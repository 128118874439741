import React from "react";
import { Icon } from "@iconify/react";

const ServiceCard = ({ IconName, Title, Description }) => {
  return (
    <div className="grid border-secondary py-5 px-8  justify-items-center m-3 border hover:bg-white  shadow-xl  rounded-lg hover:cursor-pointer  hover:scale-105 transition delay-150 ease-in-out">
      <div className="">
        <Icon icon={IconName} className="text-primary h-12 w-12 mt-4 " />
      </div>
      <div className="font-semibold text-center text-black text-xl mt-3">
        {Title}
      </div>
      <div className="text-justify text-base font-light m-4">{Description}</div>
    </div>
  );
};

export default ServiceCard;
