import React from "react";

const MessageFromDir = () => {
  return (
    <div className="m-16 font-Nunito text-justify">
      <h2 className="text-primary text-4xl font-semibold text-center mb-3">
        Message From Director
      </h2>
      <div className="grid md:grid-cols-2">
        <div>
          <img
            src="/images/carousel/vision.png"
            alt="director"
            className="w-3/5"
          />
        </div>
        <div className="text-lg">
          <p className="my-5">
            As the Director of Footstep Education Consultancy, I am proud to
            announce that we are now offering comprehensive study abroad
            services to help students achieve their dreams of pursuing higher
            education in their preferred destination.
          </p>
          <p className="my-5">
            Our team of expert consultants has extensive experience in guiding
            students through every step of the application process, from
            selecting the right course and university to securing visas and
            finding accommodation. We are committed to providing personalized
            support to each student to ensure they have a smooth and stress-free
            experience.
          </p>
        </div>
      </div>
      <div className="block text-lg">
        <p className="my-5">
          In addition to our study abroad services, we also offer classes for
          the International English Language Testing System (IELTS) and Pearson
          Test of English (PTE) to help students prepare for these exams. Our
          experienced instructors use proven teaching methods to help students
          improve their language skills and achieve their desired scores.
        </p>
        <p className="my-5">
          At Footstep Education Consultancy, we are dedicated to helping
          students achieve their goals and realize their full potential. We
          believe that studying abroad can be a life-changing experience, and we
          are here to make that dream a reality.
        </p>
        <p className="my-5">
          Thank you for considering Footstep Education Consultancy as your
          partner in your academic journey. We look forward to hearing from you
          and supporting you every step of the way.
        </p>
      </div>
    </div>
  );
};

export default MessageFromDir;
