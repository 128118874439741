import Layout from "./components/layout/Layout";
import Ielts from "./components/Test/Ielts";
import PTE from "./components/Test/PTE";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./pages/Contact";
import Homepage from "./components/Home/Homepage";
import Blogs from "./components/Blog/Blogs";
import BlogDetail from "./components/Blog/BlogDetail";
import NotFoundPage from "./pages/NotFound";
import StudyDetail from "./components/Home/DestinationSection/StudyDetail";
import AboutUs from "./pages/AboutUs";
import MessageFromDir from "./pages/MessageFromDir";

import { HelmetProvider } from "react-helmet-async";
import ServiceDetail from "./components/Home/Services/ServiceDetail";
import Enquiry from "./pages/Enquiry";
import Gallery from "./pages/Gallery";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  const routes = [
    { path: "/", component: Homepage },
    { path: "/abroad/:slug", component: StudyDetail },
    { path: "/test/ielts", component: Ielts },
    { path: "/test/pte", component: PTE },
    { path: "/contact", component: Contact },
    { path: "/blogs", component: Blogs },
    { path: "/blogs/:slug", component: BlogDetail },
    { path: "/services/:slug", component: ServiceDetail },
    { path: "/*", component: NotFoundPage },
    { path: "/about-us", component: AboutUs },
    { path: "/message-from-director", component: MessageFromDir },
    { path: "/enquiry", component: Enquiry },
    { path: "/gallery", component: Gallery },
    { path: "/privacy-policy", component: PrivacyPolicy },
  ];
  return (
    <BrowserRouter>
      <HelmetProvider>
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<Layout children={<route.component />} />}
            />
          ))}
        </Routes>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
