import React from 'react'
import { useState,useEffect} from 'react';
import FetchContentful from "../common/FetchContentful";
import ModalView from '../UI/ModalView';
import Loader from "../common/Loader";


const VideoList = () => {
  const [videoArray, setVideoArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clickedContent, setClickedContent] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const { getVideo } = FetchContentful();



  useEffect(() => {
    setLoading(true)
    getVideo("gallaries").then((res) => {
      const newVideo = res.map((respone, index)=>(
        respone.fields.videos.map((item,index)=>(
        {   
            name: item.fields.title,
            url: item.fields.file.url,
        }
        ))
      ))
      setVideoArray(newVideo);
      setLoading(false);
    });
    

  }, []);

  const handelClicked = (items, index) => {
    setCurrentIndex(index);
    setClickedContent(items.url);
  };

  const handelNext = () => {
    const totallength = videos.length;
    if (currentIndex + 1 >= totallength) {
      setCurrentIndex(0);
      const newUrl = videos[0].url;
      setClickedContent(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = videos.filter((item) => {
      return videos.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].url;
    setClickedContent(newItem);
    setCurrentIndex(newIndex);
  };
  const handelPrev = () => {
    const totallength = videos.length;
    if (currentIndex === 0) {
      setCurrentIndex(totallength - 1);
      const newUrl = videos[totallength - 1].url;
      setClickedContent(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = videos.filter((item) => {
      return videos.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].url;
    setClickedContent(newItem);
    setCurrentIndex(newIndex);
  };

  const videos = videoArray.reduce((merged, arr) => merged.concat(arr), []);
  if (loading) {
    return (
      <div className="h-[50vh] w-screen flex justify-center items-center">
        <Loader />
      </div>
    );
  } else {
    return (
        <div className="flex flex-wrap justify-around items-center">
       {videos.map((items,index) => {
            return (
              <div key={index} className="flex justify-center m-4">
               
                <video controls className='h-44'  onClick={() => handelClicked(items, index)}> 
                  <source src={items.url} type='video/mp4'/>
                  <source src={items.url} type="video/webm" />
                   <source src={items.url} type="video/ogg" />
                   your browser donot support the video
                </video>

              </div>
            );
          })} 
          <div>
        {clickedContent && (
          <ModalView
            clicked={clickedContent}
            handelNext={handelNext}
            setClickedContent={setClickedContent}
            handelPrev={handelPrev}
            contentType="video"
          />
        )}
      </div>
        </div>
        
      );}
}
export default VideoList;
