import React, { useState } from "react";
import Seo from "../../SEO/Seo";

const tabs = [
  {
    id: 1,
    title: "Ielts test Structure",
    description:
      "Listining (30 Minutes) Reading (60 Minutes) Writing (60 Minutes) Speaking (11-15 Minutes)",
  },
  {
    id: 2,
    title: "IELTS Test Requirements",
    description:
      "A complete application form A copy of valid ID card/passport Payment of USD 20",
  },
  {
    id: 3,
    title: "Score Overview",
    description:
      "Score 9  Expert User Score 8  Very good User Score 7  Good User  Score 6  Competent User Score 5  Modest User Score 4 Limited User Score 3  Extremely User Score 2 Intermittent User Score 1  Non-User Score 0  Did not attempt the test ",
  },
];

const View = [
  {
    id: 1,
    format: "Full Form",
    details: "International English Language Testing System",
  },
  {
    id: 2,
    format: "Score Range",
    details: "0 to 9 at increment of 0.5 band multiple",
  },
  {
    id: 3,
    format: "Duration",
    details: "2 hours and 45 minutes",
  },

  {
    id: 4,
    format: "Grade Validity",
    details: "2 years",
  },
  {
    id: 5,
    format: "Test official authorities",
    details: "British Council and IDP",
  },
];

const Score = [
  {
    id: 1,
    score: "Band 9",
    description: "Expert User",
  },
  {
    id: 2,
    score: "Band 8",
    description: "Very-Good User",
  },
  {
    id: 3,
    score: "Band 7",
    description: "Good User",
  },
  {
    id: 4,
    score: "Band 6",
    description: "Competent User",
  },
  {
    id: 5,
    score: "Band 5",
    description: "Modest User",
  },
  {
    id: 6,
    score: "Band 4",
    description: "Limited User",
  },
  {
    id: 7,
    score: "Band 3",
    description: "Extremely-Limited User",
  },
  {
    id: 8,
    score: "Band 2",
    description: "Intermittent User",
  },
  {
    id: 9,
    score: "Band 1",
    description: "Non-User",
  },
  {
    id: 10,
    score: "Band 0",
    description: "Did-not-attempt-the-test",
  },
];

const faqs = [
  {
    question: "What is the IELTS exam?",
    answer:
      "The International English Language Testing System (IELTS) is a test that measures the language proficiency of people who want to study or work in environments where English is used as a language of communication.",
  },
  {
    question: "What are the different types of IELTS exam?",
    answer:
      "There are two types of IELTS exams: IELTS Academic and IELTS General Training. The IELTS Academic test is for people who want to study at a higher education level or to work in a professional organization in an English-speaking country. The IELTS General Training test is for those who are going to English-speaking countries for secondary education, work experience, or training programs.",
  },
  {
    question: "How is the IELTS exam scored?",
    answer:
      "The IELTS test is scored on a nine-band scale, with each band corresponding to a level of English proficiency. The scores are rounded to the nearest half band or whole band. A score of 9 is considered an expert user of English, while a score of 1 indicates non-user of English.",
  },
  {
    question: "Where can I take IELTS?",
    answer:
      "The network of IELTS test centres managed by IDP: IELTS Australia offers more than 200 test locations in 45 countries.",
  },
  {
    question: "Can I take the IELTS exam more than once?",
    answer:
      "Yes, you can take the IELTS exam as many times as you want. However, you will need to pay the exam fee each time you take the test.",
  },
  {
    question: "How long does the IELTS exam take?",
    answer:
      "The IELTS exam takes around 2 hours and 45 minutes to complete. The Listening, Reading and Writing sections of the test are completed on the same day, while the Speaking section may be taken up to 7 days before or after the other sections.",
  },
  {
    question: "What kind of topics are covered in the IELTS exam?",
    answer:
      "The topics covered in the IELTS exam depend on the type of exam you take. The IELTS Academic exam includes academic topics related to higher education and research, while the IELTS General Training exam includes everyday topics related to work, travel, and social situations.",
  },
  {
    question: "How can I prepare for the IELTS exam?",
    answer:
      "There are various ways to prepare for the IELTS exam, such as taking practice tests, attending preparation courses, and studying grammar and vocabulary. You can also find study materials and resources online or through IELTS preparation centers.",
  },
  {
    question: "What if I need to postpone or cancel my IELTS test?",
    answer:
      "Please contact the test centre where you booked your IELTS test. If you postpone or cancel your application more than 5 weeks before the test date, you will receive a refund minus an administration charge. If you postpone or cancel within 5 weeks of the test date, you will be charged the full fee unless you have a medical reason. If you are unable to attend due to a serious medical condition and provide a medical certificate within 5 days of the test date, you will receive a refund minus the local administrative cost. If you are delayed by circumstances beyond your control (e.g. a transport strike) the test centre may offer you a test on the next available test date.",
  },
  {
    question: "What can I take into the examination room?",
    answer:
      "Only pens, pencils and erasers. You must bring the passport/national identity card you used on the IELTS Application Form to the test. You must leave everything else outside the examination room. Mobile phones and pagers must be switched off and placed with personal belongings in the area designated by the supervisor. If you do not switch off your phone/pager or if you keep it with you, you will be disqualified.",
  },
];

function FaqItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border rounded-lg p-4 mb-4">
      <div
        className="flex justify-between items-center "
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="text-lg font-bold ">{question}</div>
        <button
          className="text-gray-600 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? "-" : "+"}
        </button>
      </div>
      {isOpen && <div className="mt-4 text-gray-600">{answer}</div>}
    </div>
  );
}

// Component that renders a list of FAQ items
function FaqList({ faqs }) {
  return (
    <div>
      {faqs.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
}

const Ielts = () => {
  
  return (
    
    <course className="grid my-4">
      <Seo
        title="Ielts | Footstep Education Consultancy"
        description="We offer the best IELTS  classes, exam preparation, and study abroad consultancy services. Our experienced consultants can help you achieve your dream of studying abroad."
        name="Footstep Education Consultancy"
        type="article"
      />
      <div className="grid  gap-8 w-[80%] mx-auto ">
        {/* defination */}
        <div className="grid  lg:grid-cols-2 items-center">
          <div className="p-4 grid justify-items-center ">
            <img
              src="/images/test/IELTS.png"
              alt="image1"
              className="object-fill h-48 w-96"
            ></img>
          </div>
          <div className="text-lg lg:justify-items justify-items-center-start">
            <div className="py-4 ">What is Ielts ?</div>
            <div className="text-base text-justify">
              International English Language Testing System is an English
              language test that is internationally recognized and is specially
              designed with an assessment system that is fair to test your
              skills in English. IELTS is one of the requirements to be able to
              study or work in countries that use English as the language of
              instruction. These tests which provide an opportunity to measure
              your ability to speak English are comprised of Listening, Reading,
              Writing, and Speaking
            </div>
          </div>
        </div>
        {/* why  */}
        <div className="grid  lg:grid-cols-2 items-center">
          <div className="text-lg lg:justify-items justify-items-center-start">
            <div className="py-4">Why take IELTS ?</div>
            <div className="text-base text-justify">
              If you are looking to work, live or study in an English-speaking
              country, then you must be able to demonstrate a high level of
              English language ability.English is the third most spoken language
              in the world, with 379 million speakers worldwide.Being able to
              communicate in the native language of the country you wish to work
              or study in, has a wide range of benefits such as job
              opportunities,integration into the community.It is globally
              recognised by more than 11,000 employers, universities, schools
              and immigration bodies including 3,400 institutions in the USA.
            </div>
          </div>
          <div className="p-4 grid justify-items-center ">
            <img
              src="/images/test/ieltsprep.jpg"
              alt="image1"
              className="object-fill h-48 w-96"
            ></img>
          </div>
        </div>
        {/* how  */}
        <div className="">
          <div className="">
            <h1 className=" text-center text-primary text-3xl">
              How IELTS Academic works?
            </h1>
            <p className="text-center ">
              IELTS Academic is a standardized test designed to measure the
              English language proficiency of individuals who want to study in
              an English-speaking country. The test assesses a candidate's
              reading, writing, listening, and speaking skills in English.
            </p>
            <p className="">
              <table className="md:w-3/4 md:m-auto">
                <thead className="bg-gray">
                  <tr>
                    <th className="py-2 px-4 text-secondary">Test Format</th>
                    <th className="py-2 px-4 text-secondary">Details</th>
                  </tr>
                </thead>
                <tbody>
                  {View.map((item) => (
                    <tr key={item.id}>
                      <th className="py-2 px-4 border-b border font-medium">
                        {item.format}
                      </th>
                      <th className="py-2 px-4 border-b border font-light">
                        {item.details}
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </p>
          </div>
        </div>
        {/* score  */}

        <div className="">
          <div className="text-lg lg:justify-items justify-items-center-start">
            <div className="py-4 text-center text-primary text-3xl">
              Score Overview
            </div>
            <div className="text-base text-justify grid lg:grid-cols-2">
              <div>
                <p className="">
                  <table className=" md:w-1/2 md:m-auto ">
                    <thead className="bg-gray">
                      <tr>
                        <th className="py-2 px-8 text-lg text-secondary">
                          Score
                        </th>
                        <th className="py-2 px-8 text-lg text-secondary">
                          Description
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Score.map((item) => (
                        <tr key={item.id}>
                          <th className="py-2 px-4  font-medium">
                            {item.score}
                          </th>
                          <th className="py-2 px-4 font-normal">
                            {item.description}
                          </th>
                          {/* <th className="py-2 px-4  font-light">
                          {item.description}
                        </th> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </p>
              </div>

              <div className="p-8 grid justify-items-center items-center">
                <img
                  src="/images/test/ieltsscore.jpg"
                  alt="image1"
                  className="object-fill h-56 hidden md:block"
                ></img>
              </div>
            </div>
          </div>
        </div>
        {/* FAQ */}
        <div className="text-3xl font-bold mb-4 text-center text-secondary">
          IELTS FAQ
        </div>
        <FaqList faqs={faqs} />
      </div>
    </course>
  );
};

export default Ielts;
