import React from 'react'
import FormInput from '../UI/FormInput'

const JobInfo = ({register,errors}) => {
  return (
    <div className='flex flex-col'>
      <div className="flex justify-start flex-wrap gap-3">
     <FormInput
        label="Job"
        id="job"
        type="text"
        width='full'
        className='max-w-xs'
        register={register}
        error={errors.job}
      />
      <FormInput
        label="Work Experience (In year)"
        id="experience"
        type="number"
        width='full'
        className='max-w-xs'
        register={register}
        error={errors.experience}
      />
       
      </div>
      <div className="flex justify-start flex-wrap gap-3">
      <FormInput
        label="From"
        id="jobstart"
        type="date"
        className="max-w-[150px]"
        register={register}
        error={errors.jobstart}
      />
      <FormInput
        label="To"
        id="jobend"
        type="date"
        className="max-w-[150px]"
        register={register}
        error={errors.jobend}
      />
      </div>
     <div className="flex justify-start flex-wrap gap-3">
    {/* salarymode  */}
    <div className="flex flex-col gap-2 items-start w-full sm:w-80 max-w-xs">
      <label htmlFor='salarymode' className="text-base md:text-xl font-Poppins mt-4">
        Salary Mode
      </label>
      <select
      id='salarymode'
      className={`border  px-2 py-1 rounded-md w-full ${errors.salarymode?'border-red':'border-border'}`}
      {...register('salarymode')}>
        <option value="">Select</option>
        <option value="Cash">Cash</option>
        <option value="Bank">Bank</option>
      </select>
      {errors.salarymode && <p className='text-red px-2'>{errors.salarymode.message}</p>}
      </div>
      
      

      {/* experienceletter  */}
      <div className="flex flex-col gap-2 items-start w-full sm:w-80 max-w-xs">
      <label htmlFor='experienceletter' className="text-base md:text-xl font-Poppins mt-4">
        Experience Letter
      </label>
      <select
      id='experienceletter'
      className={`border  px-2 py-1 rounded-md w-full ${errors.experienceletter?'border-red':'border-border'}`}
      {...register('experienceletter')}>
        <option value="">Select</option>
        <option value="yes">yes</option>
        <option value="no">no</option>
      </select>
      {errors.experienceletter && <p className='text-red px-2'>{errors.experienceletter.message}</p>}
      
      </div>
      </div>
    </div>
  )
}

export default JobInfo
