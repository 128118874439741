import React from 'react';

const FormInput = ({ label, id, type, className, register, error, placeholder }) => {
  const errorMessage = error ? error.message : '';


  return (
    <div className="flex flex-col gap-2 items-start w-full sm:w-80 max-w-xs">
      <label htmlFor={id} className='text-base md:text-xl font-Poppins mt-4'>
        {label}
      </label>
        <input
          type={type}
          id={id}
          placeholder={placeholder}
          className={`border  px-2 py-1 rounded-md w-full ${errorMessage?'border-red':'border-border'} ${className}`}
          {...register(id)}
        />
        {error && <p className='text-red px-2'>{errorMessage}</p>}
    </div>
  );
};

export default FormInput;
