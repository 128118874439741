import React from "react";

const BannerwithText = ({ ImageUrl, Text, Slug }) => {
  return (
    <div>
      <div className=" flex flex-col relative  text-white  h-fit">
        <img
          src={ImageUrl}
          alt="bg"
          className="brightness-50 h-[33vh]  md:h-[45vh]  w-full "
        />
        <div className="absolute tracking-wide inset-y-14 md:inset-y-32  inset-x-10 md:inset-x-40">
          <h1 className=" text-3xl md:text-5xl font-bold   ">{Text}</h1>
          <h2 className="text-xl  md:text-2xl font-semibold"> Home/{Slug} </h2>
        </div>
      </div>
    </div>
  );
};

export default BannerwithText;
