import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="flex flex-col  bg-primary px-5  md:px-32 text-white py-4 md:py-10">
        <h1 className=" text-2xl md:text-5xl font-bold   ">Privacy Policy</h1>
        <h2 className=" text-lg md:text-2xl font-semibold">
          Home/privacy policy
        </h2>
      </div>

      <div className="mx-12 text-justify text-xl">
        <p className="my-4">
          Thank you for considering Footstep Education Consultancy as your
          trusted education consultancy service provider. We are committed to
          protecting your privacy and ensuring the security of your personal
          information. This privacy policy outlines the types of personal
          information we collect from you, how we use it, and how we keep it
          secure.
        </p>
        <h1 className="font-bold text-2xl"> 1. Personal information we collect:</h1>
        <p className="my-4">
          We collect various types of personal information from you, including
          your name, email address, phone number, postal address, academic
          records, and other information relevant to our service. We collect
          this information from you when you provide it to us directly or when
          you use our website or other communication channels.
        </p>
        <h1 className="font-bold text-2xl"> 2. How we use your personal information:</h1> 
        <p className="my-4">
          We use your personal information to provide you with our consultancy
          services, including advising you on educational opportunities,
          processing your applications, and communicating with you regarding
          your educational pursuits. We may also use your information to improve
          our service, conduct research, and comply with legal requirements.
        </p>{" "}
        <h1 className="font-bold text-2xl">3. How we protect your personal information: </h1>{" "}
        <p className="my-4">
          We take appropriate technical and organizational measures to protect
          your personal information against unauthorized or unlawful processing,
          accidental loss, or destruction. We limit access to your personal
          information to only those who need to know it for legitimate business
          purposes.
        </p>{" "}
        <h1 className="font-bold text-2xl">4. How we share your personal information: </h1>{" "}
        <p className="my-4">
          {" "}
          We may share your personal information with our partner universities
          or educational institutions to facilitate your admission process. We
          may also share your information with third-party service providers who
          assist us with our business operations, such as website hosting, data
          analytics, and marketing. We will only share your personal information
          with third-party providers who have agreed to comply with our strict
          data protection standards.
        </p>{" "}
        <h1 className="font-bold text-2xl">5. Your rights: </h1>{" "}
        <p className="my-4">
          You have the right to access, correct, or delete your personal
          information that we hold. You can also object to the processing of
          your personal information, restrict its processing, or request the
          transfer of your personal information to another organization. Please
          contact us if you have any questions or concerns about your privacy
          rights.
        </p>{" "}
        <h1 className="font-bold text-2xl"> 6. How we use cookies and other tracking technologies: </h1>{" "}
        <p className="my-4">
          {" "}
          We use cookies and other tracking technologies to analyze how our
          website is used and to provide personalized content and
          advertisements. Cookies are small data files that are stored on your
          device when you visit our website. You can choose to accept or decline
          cookies through your browser settings. However, please note that
          disabling cookies may affect the functionality of our website.{" "}
        </p>{" "}
        <h1 className="font-bold text-2xl"> 7. Children’s privacy: </h1>
        <p className="my-4">
          {" "}
          Our services are not directed at children under the age of 16. We do
          not knowingly collect personal information from children under the age
          of 16. If we become aware that we have collected personal information
          from a child under the age of 16, we will take steps to delete that
          information as soon as possible.
        </p>{" "}
        <h1 className="font-bold text-2xl">8.  Changes to this policy: </h1>{" "}
        <p className="my-4">
          We may update this privacy policy from time to time to reflect changes
          in our business operations or legal requirements. We encourage you to
          review this policy periodically to stay informed about how we protect
          your personal information.
        </p>{" "}
        <h1 className="font-bold text-2xl">9. Contact us:</h1>
        <p className="my-4">
          If you have any questions or concerns about our privacy policy, please
          contact us at info@footstepeducation.com. We will do our best to
          respond to your inquiry as soon as possible.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
