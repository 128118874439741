import React from "react";
import { GlobeAsiaAustraliaIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Icon } from "@iconify/react";

const Icons = [
  {
    id: "1",
    iconName: "iconoir:tiktok",
    linkUrl: "https://www.tiktok.com/@footstep_edu",
  },
  {
    id: "2",
    iconName: "iconoir:youtube",
    linkUrl: "",
  },
  {
    id: "3",
    iconName: "ic:baseline-facebook",
    linkUrl: "https://www.facebook.com/profile.php?id=100085629246794",
  },
  {
    id: "4",
    iconName: "prime:instagram",
    linkUrl: "/",
  },
];

const Contacts = () => {
  return (
    <div className="flex flex-wrap px-5 gap-2 w-[100%] justify-between bg-slate text-white lg:px-20 py-3 ">
      <address className="flex gap-1 flex-wrap md:gap-3">
        <h1 className=" flex gap-2">
          <span>
            <GlobeAsiaAustraliaIcon className="h-6 w-6" />
          </span>
          <span>
            <a href="mailto:edu.footstep@gmail.com">edu.footstep@gmail.com</a>
          </span>
        </h1>
        <h1 className="flex">
          <span>
            <PhoneIcon className=" h-6 w-6" />
          </span>
          <a href="tel:061-590154"><span className="mx-2">061-590154</span></a>
          <a href="tel:+9779802855828"><span className="mx-2">9802855828</span></a>
        </h1>
      </address>
      <div className="flex gap-3 lg:gap-6">
        {Icons.map((item) => {
          return (
            <a key={item.id} href={item.linkUrl} target="_blank"rel="noreferrer noopener">
              <Icon
                icon={item.iconName}
                className="hover:scale-125 transition duration-300 ease-out hover:ease-in hover:text-secondary  lg:h-7 lg:w-7 "
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Contacts;
