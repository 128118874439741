import React from "react";
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation, Pagination]);

const Testimonial = () => {
  const testimonials = [
    {
      id: 1,
      name: "Nabina Thapa",
      quote:
        "I had an excellent experience with this education consultancy. They helped me choose the perfect program for my career goals and provided support throughout the entire process.",
      jobTitle: "Recent Graduate",
      image: "images/testimonials/test1.png",
    },
    {
      id: 2,
      name: "Saroj Kandel",
      quote:
        "Thanks to this consultancy, I was able to secure a scholarship to study abroad. The staff is knowledgeable and went above and beyond to help me achieve my dreams.",
      jobTitle: "Engineering Student",
      image: "images/testimonials/test2.png",
    },
    {
      id: 3,
      name: "Manisha Saru",
      quote:
        "I highly recommend this education consultancy to anyone who wants to study abroad. Their guidance and support made the application process stress-free. ",
      jobTitle: "Medical Student",
      image: "images/testimonials/test3.png",
    },
    {
      id: 4,
      name: "Bipin Lamsal",
      quote:
        "I was feeling overwhelmed, but the education consultancy made the process a breeze. The team provided expert guidance every step of the way.",
      jobTitle: "Engineering Student",
      image: "images/testimonials/test4.png",
    },
    {
      id: 5,
      name: "Niruta Acharya",
      quote:
        "The education consultancy helped me navigate the complex world of graduate school admissions with ease. They provided personalized support and advice that helped me put together a strong application.",
      jobTitle: "Recent Graduate",
      image: "images/testimonials/test5.png",
    },
    {
      id: 6,
      name: "Neha Gurung",
      quote:
        "They provided unbiased advice and helped me weigh the pros and cons of each option, ultimately guiding me towards a program that aligned with my passions and goals.",
      jobTitle: "Medical Student",
      image: "images/testimonials/test6.png",
    },
    {
      id: 7,
      name: "Usha Adhikari",
      quote:
        "The education consultancy provided invaluable support throughout my entire academic journey.Their expert advice and guidance helped me achieve academic success.",
      jobTitle: "Recent Graduate",
      image: "images/testimonials/test7.png",
    },
  ];
  // const isSmallScreen = window.innerWidth < 768;
  return (
    <div className="py-12 lg:mx-20 font-Nunito ">
      <div className=" px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-bold uppercase ">Student Testimonial</h1>
          <h3 className=" text-2xl font-semibold my-5 ">
            Stories from our students
          </h3>
        </div>
        <div className="mt-10">
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            rewind={true}
            autoplay={{
              delay: 2000,
            }}
            pagination={{ clickable: false, dynamicBullets: true }}
            navigation={false}
            loop={true}
            mousewheel={true}
            keyboard={true}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay, Pagination, Navigation, Mousewheel, Keyboard]}
          >
            {testimonials.map((test) => {
              return (
                <div
                  key={test.id}
                  className="bg-white  overflow-hidden shadow rounded-full divide-y "
                >
                  <SwiperSlide key={test.id}>
                    <div className=" max-w-xl mx-auto flex flex-row items-center">
                      <div className="flex flex-row rounded-lg shadow-lg overflow-hidden w-full mb-12 md:h-64 lg:h-56">
                        <div className="flex-shrink-0 mt-10 p-3">
                          <img
                            className=" w-20 h-20 rounded-full"
                            src={test.image}
                            alt={test.name}
                          />
                          <p className="mt-3 text-sm leading-6">
                            - {test.name}, <br /> {test.jobTitle}
                          </p>
                        </div>
                        <div className="flex-1 bg-white p-6 flex flex-row justify-between">
                          <div className="grid items-center">
                            <h3 className=" text-center  text-base leading-5 font-semibold">
                              {test.quote}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </div>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
