import React from "react";
import { Icon } from "@iconify/react";

const Header = () => {
  const Achievements = [
    {
      id: 11,
      icons: "mdi:graph-line",
      // icons: "game-icons:progression",
      title: "High Visa Sucess Rate",
    },
    {
      id: 12,
      icons: "cil:badge",
      // icons: "carbon:certificate",
      title: "Certified Career Counselors",
    },
    {
      id: 13,
      icons: "fluent:people-team-20-regular",
      // icons: "vaadin:group",
      title: "Visa Expert Team",
    },
    {
      id: 14,
      icons: "nimbus:university",
      // icons: "la:university",
      title: "500 + University",
    },
  ];

  return (
    <div className="bg-[#f9f9fa] font-Nunito py-10">
      <div className="text-center mb-8 mx-auto p-4 max-w-4xl ">
        <div className="   font-semibold text-2xl md:text-4xl my-3">
          Welcome to Footstep Education Consultancy
        </div>
        <div className="  text-secondaryBlack text-lg md:text-2xl font-semibold mx-3  my-3 md:my-8 p-3">
          Leading Consultancy In Nepal For Abroad Study
        </div>
        <div className="text-base md:text-xl font-medium m-3 p-3">
          Looking to study abroad? Let Foot Step help you! Our team of experts
          will guide you to the best study path for your future. We specialize
          in assisting Nepalese students to reach their study destinations in
          the USA, UK, Australia, Canada and New Zealand. With high visa success
          rates and scholarships up to 100% tuition, we maximize your chances of
          success. Contact us today for consulting by experts, visa interview
          tips and documentation guidance, and travel arrangements.
        </div>
      </div>
      <div className="grid md:grid-cols-4 grid-cols-2  m-4 justify-items-center max-w-5xl mx-auto ">
        {Achievements.map((items) => {
          return (
            <div key={items.id} className="justify-items-center m-3 p-3">
              <div className="grid  justify-center">
                <Icon
                  icon={items.icons}
                  className="h-16 w-16 font-thin text-primary  "
                />
              </div>
              <div className=" text-center text-md font-semibold w-32 my-2 ">
                {items.title}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Header;
