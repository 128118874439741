import React, { useState } from "react";
import Seo from "../../SEO/Seo";

const Score = [
  {
    id: 1,
    score: 90,
    review: "Native-like proficiency",
    description:
      "  Candidates who score a 90 have excellent command of the English language and are capable of using English with precision and fluency.",
  },
  {
    id: 2,
    score: "79 - 89",
    review: "Very-Good Proficiency",
    description:
      " Candidates who score in this range have a strong command of the English language and are able to communicate effectively in most situations.",
  },
  {
    id: 3,
    score: "65 - 78",
    review: "Good proficiency",
    description:
      "Candidates who score in this range have a competent command of the English language and are able to communicate effectively in many situations.",
  },
  {
    id: 4,
    score: "50 - 64",
    review: "Moderate proficiency",
    description:
      "Candidates who score in this range have a basic command of the English language and are able to communicate in familiar situations but may struggle in unfamiliar contexts.",
  },
  {
    id: 5,
    score: "30 - 49",
    review: "Limited proficiency",
    description:
      " Candidates who score in this range have a limited command of the English language and may struggle to communicate in most situations.",
  },
  {
    id: 6,
    score: "10 - 29",
    review: "Very-Limited proficiency",
    description:
      "Candidates who score in this range have a very limited command of the English language and may only be able to communicate in very basic situations.",
  },
];

const View = [
  {
    id: 1,
    format: "Mode",
    details: "Taken in a test center",
  },
  {
    id: 2,
    format: "Medium",
    details: "Computer-based with headset",
  },
  {
    id: 3,
    format: "Duration",
    details: "2 hours long and single test session",
  },

  {
    id: 4,
    format: "Test Type",
    details: "Assesses real-life English",
  },
  {
    id: 5,
    format: "Test Layout",
    details: "20 question types",
  },
  {
    id: 6,
    format: "Score Range",
    details: "10-90",
  },
];

const faqs = [
  {
    question: "What is the PTE exam?",
    answer:
      "The Pearson Test of English (PTE) is a computer-based English language proficiency test that assesses the speaking, writing, listening, and reading skills of non-native English speakers.",
  },
  {
    question: "What are the different types of PTE exams?",
    answer:
      "There are several types of PTE exams: PTE Academic, PTE General, and PTE Young Learners. The PTE Academic exam is for people who want to study at a higher education level or to work in a professional organization in an English-speaking country. The PTE General test is for those who want to test their everyday English language skills, while the PTE Young Learners test is for children aged 6-13.",
  },
  {
    question: "How is the PTE exam scored?",
    answer:
      "The PTE test is scored on a scale of 10-90, with each score level corresponding to a level of English proficiency. The scores are calculated based on the performance in each of the four language skills, and the final score is an average of the four individual scores.",
  },
  {
    question:
      "Is there any chance to lose points if you take time to give answers?",
    answer:
      " If you take more time in the speaking section, you might be left with less time to answer the other sections. So remember, time management is critical for the PTE examination.",
  },
  {
    question: "Can I take the PTE exam more than once?",
    answer:
      "Yes, you can take the PTE exam as many times as you want. However, you will need to pay the exam fee each time you take the test.",
  },
  {
    question: "How long does the PTE exam take?",
    answer:
      "The PTE exam takes around 3 hours to complete. The Speaking and Writing sections of the test are completed on the same day, while the Reading and Listening sections may be taken up to 24 hours before or after the other sections.",
  },
  {
    question: "What kind of topics are covered in the PTE exam?",
    answer:
      "The topics covered in the PTE exam depend on the type of exam you take. The PTE Academic exam includes academic topics related to higher education and research, while the PTE General test includes everyday topics related to work, travel, and social situations.",
  },
  {
    question: "How can I prepare for the PTE exam?",
    answer:
      "There are various ways to prepare for the PTE exam, such as taking practice tests, attending preparation courses, and studying grammar and vocabulary. You can also find study materials and resources online or through PTE preparation centers",
  },
  {
    question: "In the speaking test, is it okay to speak fast to score more? ",
    answer:
      "No, by speaking fast, you can lose points. Your words can get unclear because of the speed. Keep a steady pace to sound clear both in your thoughts and in your words. ",
  },
  {
    question: "How to score good marks in PTE?",
    answer:
      "You can also follow these techniques => Be fluent, Do not use fake accents, Use intonations at the right time and the right place, Avoid pauses longer than 3 seconds, Do not rush and avoid speaking after the beep sound in the speaking section, Avoid grammatical and spelling mistakes, Learn to create complex sentences, Be aware of negative marking, Learn the rule of elimination, Speaking and listening sections are interrelated. So attempt questions carefully",
  },
];

function FaqItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border rounded-lg p-4 mb-4">
      <Seo
        title="PTE | Footstep Education Consultancy"
        description="We offer the best  PTE classes, exam preparation, and study abroad consultancy services. Our experienced consultants can help you achieve your dream of studying abroad."
        name="Footstep Education Consultancy"
        type="article"
      />
      <div
        className="flex justify-between items-center "
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="text-lg font-bold">{question}</div>
        <button
          className="text-gray-600 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? "-" : "+"}
        </button>
      </div>
      {isOpen && <div className="mt-4 text-gray-600">{answer}</div>}
    </div>
  );
}

// Component that renders a list of FAQ items
function FaqList({ faqs }) {
  return (
    <div>
      {faqs.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
}

const PTE = () => {
  return (
    <course className="grid my-4 font-Poppins">
      <div className="grid  gap-8 w-[80%] mx-auto ">
        {/* defination */}
        <div className="grid  lg:grid-cols-2 items-center">
          <div className="p-4 grid justify-items-center">
            <img
              src="/images/test/PTE.png"
              alt="image1"
              className="object-fill h-48 w-96 "
            ></img>
          </div>
          <div className="text-lg lg:justify-items justify-items-center-start">
            <div className="py-4">What is PTE ?</div>
            <div className="text-base text-justify">
              PTE stands for Pearson Test of English. It is a computer-based
              language proficiency test that assesses the English language
              proficiency of non-native English speakers. PTE offers two types
              of tests: PTE Academic and PTE General. <br />
              Both PTE Academic and PTE General are developed and managed by
              Pearson, a global education company. The tests are recognized by
              universities, colleges, governments, and organizations worldwide
              as a reliable measure of English language proficiency.
            </div>
          </div>
        </div>
        {/* why  */}
        <div className="grid  lg:grid-cols-2 items-center">
          <div className="text-lg lg:justify-items justify-items-center-start">
            <div className="py-4">Why take PTE ?</div>
            <div className="text-base text-justify">
              PTE, or Pearson Test of English, is a popular choice for
              individuals who need to demonstrate their proficiency in the
              English language for academic or professional purposes. There are
              several reasons why someone might choose PTE over other English
              proficiency tests such as TOEFL or IELTS: <br />
              <p className="">
                <span className="font-semibold">Fast and flexible</span>
                <br />
                <span className="font-semibold">Computer-based</span> <br />
                <span className="font-semibold">
                  Real-life language skills
                </span>{" "}
                <br />
                <span className="font-semibold">Widely recognized</span>
                <br />
              </p>
            </div>
          </div>
          <div className=" p-4 grid justify-items-center">
            <img
              src="/images/test/pteprep.jpg"
              alt="image1"
              className="object-fill h-48 w-96"
            ></img>
          </div>
        </div>

        <div className="">
          <div className="">
            <h1 className=" text-center text-primary text-3xl">
              How PTE Academic works?
            </h1>
            <p className="text-center ">
              PTE Academic is administered on a computer in a safe and welcoming
              test center setting. It assesses your abilities in speaking,
              writing, reading, and listening.
            </p>
            <p className="">
              <table className="md:w-3/4 md:m-auto">
                <thead className="bg-gray">
                  <tr>
                    <th className="py-2 px-4 text-secondary">Test Format</th>
                    <th className="py-2 px-4 text-secondary">Details</th>
                  </tr>
                </thead>
                <tbody>
                  {View.map((item) => (
                    <tr key={item.id}>
                      <th className="py-2 px-4 border-b border font-medium">
                        {item.format}
                      </th>
                      <th className="py-2 px-4 border-b border font-light">
                        {item.details}
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </p>
          </div>
        </div>

        {/* score  */}
        <div className="">
          <div className="text-lg lg:justify-items justify-items-center-start">
            <div className="py-4 text-center text-primary text-3xl">
              Score Overview
            </div>
            <div className="text-base text-justify grid lg:grid-cols-2">
              <div>
                <p className="">
                  <table className=" md:w-1/2 md:m-auto">
                    <thead className="bg-gray">
                      <tr>
                        <th className="py-2 px-8 text-lg text-secondary">
                          Score
                        </th>
                        <th className="py-2 px-8 text-lg text-secondary">
                          Description
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Score.map((item) => (
                        <tr key={item.id}>
                          <th className="py-2 px-4  font-medium">
                            {item.score}
                          </th>
                          <th className="py-2 px-4 font-normal">
                            {item.review}
                          </th>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </p>
              </div>
              <div className="p-8 origin-center grid justify-items-center  md:block">
                <img
                  src="/images/test/ptescore.png"
                  alt="image1"
                  className="object-fill h-56"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="text-3xl font-bold mb-4 text-center text-secondary">
          PTE FAQ
        </div>
        <FaqList faqs={faqs} />
      </div>
    </course>
  );
};

export default PTE;
