import { useState } from "react";
import Button from "../common/Button";
import Progress from "./progress";
import PersonalInfo from "./personalInfo";
import IeltsInfo from "./ieltsInfo";
import JobInfo from "./jobInfo";
import AdditionalInfo from "./additionalInfo";
import EducationInfo from "./educationInfo";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SubmissionMessage from "../common/SubmissionMessage";
import { Link } from "react-router-dom";


const validationSchema = Yup.object().shape({
  fname: Yup.string().required("Required"),
  lname: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  dob: Yup.date().typeError("Date of Birth is Required").required("Required"),
  contact: Yup.number("invalid")
    .typeError("Please type number")
    .required("Required").test('is-ten-digits', 'Contact must be 10 digits', (value) => {
      if (value === undefined || value === null) {
        return true; // Allow empty value
      }
      const stringValue = value.toString();
      return stringValue.length === 10 && /^\d+$/.test(stringValue); // Validate 10 digits
    }),
  course: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  gender: Yup.string().required("Required"),
  maritialstatus: Yup.string().required("Required"),
  listening: Yup.number().transform((value) => (isNaN(value) ? undefined : value))
  .test("is-decimal", "Invalid decimal value", (value) => {
    if (value === undefined || value === null) {
      return true; 
    }
    return /^\d+(\.\d+)?$/.test(value.toString()); 
  }).required("Required"),
  speaking: Yup.number().transform((value) => (isNaN(value) ? undefined : value))
  .test("is-decimal", "Invalid decimal value", (value) => {
    if (value === undefined || value === null) {
      return true; 
    }
    return /^\d+(\.\d+)?$/.test(value.toString()); 
  }).required("Required"),
  Reading: Yup.number().transform((value) => (isNaN(value) ? undefined : value))
  .test("is-decimal", "Invalid decimal value", (value) => {
    if (value === undefined || value === null) {
      return true; 
    }
    return /^\d+(\.\d+)?$/.test(value.toString()); 
  }).required("Required"),
  writing: Yup.number().transform((value) => (isNaN(value) ? undefined : value))
  .test("is-decimal", "Invalid decimal value", (value) => {
    if (value === undefined || value === null) {
      return true; 
    }
    return /^\d+(\.\d+)?$/.test(value.toString()); 
  }).required("Required"),
 
 
});

const EnquiryForm = () => {
  const [page, setPage] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors ,isSubmitting,isValid}, reset
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const BackHandler = () => {
    
    setPage((page) => Math.max(page - 1, 0));
   
  };
  const NextHandler = () => {
  
    setPage((page) => Math.min(page + 1, forms.length - 1));
    
  };
  const handleFormSubmit = (data) => {
    fetch("https://formspree.io/f/xdovyyzq", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    setSubmitted(true); // Set the submission status to true
  };

  const resetFormAndStartOver = () => {
    reset(); // Reset the form
    setPage(0); // Go to the start of the form
    setSubmitted(false); // Reset the submission status
  };
  const FormTitles = [
    "Personal Information",
    "Academic Information",
    "Ielts Information",
    "job Information",
    "Other information",
  ];

  const forms = [
    <PersonalInfo register={register} errors={errors} />,
    <EducationInfo register={register} errors={errors} />,
    <IeltsInfo register={register} errors={errors} />,
    <JobInfo register={register} errors={errors} />,
    <AdditionalInfo register={register} errors={errors} />,
  ];

  return (
    <div className="container flex flex-col mx-auto my-10 px-5">
      <div className="mb-5">
        <Progress currentStep={page + 1} />
      </div>
      {submitted ? (
          <div className="flex flex-col items-center">
            <SubmissionMessage/>
            <div className="flex gap-4">
            <Button onClick={resetFormAndStartOver}>Re Submit</Button>
            <Link to='/'>
            <Button>Home</Button>
            </Link>
            </div>
          </div>
        ) : (<>
      <div className="text-center text-lg md:text-2xl font-Poppins font-semibold text-secondary">
        {FormTitles[page]}
      </div>
      <div>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          {forms[page]}
          <div className="flex items-start gap-5 my-5">
            <Button type='button' onClick={BackHandler}>Back</Button>
            <Button type='button' onClick={NextHandler}>Next</Button>
          </div>
          {FormTitles[page]==='Other information'?
          (<div className="flex items-center my-5">
          <Button type="submit"
          disabled={isSubmitting|| !isValid}
          >Submit</Button>
          </div>):''
          }
        </form>
      </div>
      </>)}
    </div>
  );
};

export default EnquiryForm;
