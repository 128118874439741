import React, { useState } from "react";
import { Icon } from "@iconify/react";

const Gotop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const upHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVisible);
  const upbutton = (
    <div
      className=" bg-primary p-4 rounded-full z-50"
      onClick={upHandler}
    >
      <Icon icon="ic:baseline-arrow-upward" className=" h-4 w-4  text-gray  " />
    </div>
  );

  return <>{visible && upbutton} </>;
};

export default Gotop;
