import React from "react";
import { Icon } from "@iconify/react";

const rawData = [
  {
    icon: "ph:globe-hemisphere-east-fill",
    number: "10",
    text: "countries",
  },
  {
    icon: "game-icons:graduate-cap",
    number: "1000",
    text: "Graduates",
  },
  {
    icon: "la:university",
    number: "500",
    text: "Universities",
  },
  {
    icon: "material-symbols:more-time",
    number: "2",
    text: "years",
  },
];

const AboutUs = () => {
  return (
    <>
      <div className="font-Nunito">
        <div className=" flex justify-center bg-green-500  mb-10">
          <div className="flex">
            {/* Photo Part */}
            <div className="w-2/5  bg-blue-500  rounded-full rounded-l-none ">
              <img
                className="w-full h-full overflow-hidden"
                src="https://assets.studies-overseas.com/banner_girl_1b83fde144.svg"
                alt="aboutimage"
              />
            </div>

            {/* Text Part */}
            <div className="flex-1">
              <div class="md:flex-shrink-0 md:h-28 pl-10"></div>
              <div className="">
                <h2 class="text-white text-3xl font-bold my-4 mx-5">
                  About Us
                </h2>
                <p class="text-lg text-gray  mx-5">
                  FootStep Education Consultancy is a career consulting
                  institute offering complete overseas education solutions to
                  the students planning to study overseas. We have been
                  assisting students in their quest for international education
                  for over 7 years now.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 className="font-bold text-center text-primary text-3xl mt-2">
        Why choose Us?
      </h2>
      <div className="grid md:grid-cols-2 text-lg m-5">
        <div className="m-5 ">
          <p className="mb-4 text-justify">
            Footstep Education Consultancy offers a personalized and streamlined
            approach to studying abroad, with a dedicated team providing
            comprehensive resources and guidance throughout the process.
          </p>
          <p>
            With an extensive network of partner institutions worldwide, the
            consultancy offers a wide range of educational opportunities
            tailored to each student's needs and goals.
          </p>
        </div>

        <div className=" grid items-center justify-center mx-10">
          <ul className="list-disc">
            <li>Footstep Represents Over 500+ Universities Worldwide</li>
            <li>
              Professional & Experienced Trained Councelors to guide your
              Journey
            </li>
            <li>Expert Guidance for Selection of Course and University.</li>
            <li>
              Hosting Direct interviews from institution's representatives.
            </li>
            <li>
              Comprehensive & Unbaised information on various Study Options
            </li>
            <li>
              Most Of Our Enrolled Sudents are References from previous
              satisfied students
            </li>
          </ul>
        </div>
      </div>

      <h3 className=" block text-primary text-center text-2xl font-semibold">
        Our Mission
      </h3>

      <div className="flex flex-col-reverse md:flex-row mx-10 mb-5">
        <div className="text-lg flex justify-items-center items-center text-justify">
          <p>
            Our purpose is to serve as a bridge and to encourage Student to
            pursue their academic goals. Our main goal is to help students make
            informed decisions about a wide range of courses and career options
            based on their educational background, interests, abilities, and
            aspirations.
          </p>
        </div>
        <div className="flex align-center justify-items-center items-center mx-auto">
          <img
            src="/images/carousel/mission.png"
            alt="mission"
            className="w-3/5 "
          />
        </div>
      </div>

      <h3 className=" block text-primary text-center text-2xl font-semibold">
        Our Vision
      </h3>
      <div className="flex flex-col md:flex-row mx-10 ">
        <div className="flex align-center justify-items-center items-center mx-auto">
          <img
            src="/images/carousel/vision.png"
            alt="mission"
            className="w-3/5 "
          />
        </div>
        <div className="text-lg flex justify-items-center items-center text-justify">
          <p>
            To establish a world-class educational facility where
            administration, consulting, and resources of the highest caliber can
            offer comprehensive educational answers to the current educational
            challenges
          </p>
        </div>
      </div>

      <div className="grid bg-blue-200 lg:grid-cols-4  divide-y md:divide-y-0 md:divide-x divide-orange-400 items-center font-medium text-primary">
        {rawData.map((items) => {
          return (
            <div className="grid justify-items-center text-lg">
              <Icon
                icon={items.icon}
                className="h-8 w-8  "
              />
              <p className="text-lg"> {items.number} +</p>
              <p>{items.text}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AboutUs;
