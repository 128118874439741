import { Fragment } from "react";
import React, { useState, useRef } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import MenuClone from "./MenuLayout";
const Navlinks = (props) => {
  const dropdownRef = useRef(null);

  const [openDropdown, setOpenDropdown] = useState(false);
  const [mouseOverButton, setMouseOverButton] = useState(false);
  const [mouseOverMenu, setMouseOverMenu] = useState(false);

  const timeoutDuration = 200;
  let timeoutButton;
  let timeoutMenu;

  const onMouseEnterButton = () => {
    clearTimeout(timeoutButton);
    setOpenDropdown(true);
    setMouseOverButton(true);
  };
  const onMouseLeaveButton = () => {
    timeoutButton = setTimeout(
      () => setMouseOverButton(false),
      timeoutDuration
    );
  };

  const onMouseEnterMenu = () => {
    clearTimeout(timeoutMenu);
    setMouseOverMenu(true);
  };
  const onMouseLeaveMenu = () => {
    timeoutMenu = setTimeout(() => setMouseOverMenu(false), timeoutDuration);
  };

  const show = openDropdown && (mouseOverMenu || mouseOverButton);

  const { title, subTopics } = props;

  return (
    <Menu as="div" className="relative ml-3">
      {({ open }) => (
        <>
          {" "}
          <div
            onClick={() => setOpenDropdown(!openDropdown)}
            onMouseEnter={onMouseEnterButton}
            onMouseLeave={onMouseLeaveButton}
            role="button"
            tabIndex="0"
          >
            <Menu.Button className="flex px-2 font-semibold py-1 bg-gray-800 text-sm ">
              {title}
              <span>
                <ChevronDownIcon className="text-black h-4 w-4 mt-0.5 ml-1" />
              </span>
            </Menu.Button>
          </div>
          <Transition
            show={show}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              ref={dropdownRef}
              onMouseEnter={onMouseEnterMenu}
              onMouseLeave={onMouseLeaveMenu}
              className="absolute left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {subTopics
                ? subTopics.map((item, index) => {
                    return (
                      <MenuClone
                        key={index}
                        label={item.topic}
                        linkURL={item.linkURL}
                      />
                    );
                  })
                : null}
            </Menu.Items>
          </Transition>{" "}
        </>
      )}
    </Menu>
  );
};

export default Navlinks;
