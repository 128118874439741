import React, { useState } from 'react'
import FormInput from '../UI/FormInput'
import Button from '../common/Button'





const EducationInfo = ({ register, errors }) => {

  const [plustwo, setPlustwo]= useState(false);
  
  const [bachelor, setBachelor]= useState(false);
  const [master, setMaster]= useState(false);
  const removeHandeler=(indicator)=>{
    if(indicator==="Plustwo"){  
   setPlustwo(false);
    }else if(indicator==="Bachelor"){  
      setBachelor(false);
       }else if(indicator==="Master"){  
        setMaster(false);
         }
  }
  const addHandeler=(indicator)=>{
    if(indicator==="Plustwo"){  
   setPlustwo(true);
    }else if(indicator==="Bachelor"){  
      setBachelor(true);
       }else if(indicator==="Master"){  
        setMaster(true);
         }
  }



  return (
    <div className='flex flex-col'>
      <div className='grid border-b border-b-slate py-5'>
      <h3 className='text-lg md:text-xl text-center underline-offset-8 underline font-medium py-4'> SLC / SEE </h3>
      <div className="flex justify-start flex-wrap gap-3">

      
      <FormInput
        label="Subject"
        id="SLCsubject"
        type="text"
        register={register}
        error={errors.SLCsubject}
      />
      <FormInput
        label="Percentage / GPA"
        id="SLCpercentage"
        type="text"
        register={register}
        error={errors.SLCpercentage}
      />
      <FormInput
        label="Board"
        id="SLCboard"
        type="text"
        register={register}
        error={errors.SLCboard}
      />
      
      </div>
      <div className="flex justify-start flex-wrap gap-3">
      <FormInput
        label="Backlog"
        id="SLCbacklog"
        type="number"
        className='max-w-[150px]'
        register={register}
        error={errors.SLCbacklog}
      />
      <FormInput
        label="End"
        id="SLCend"
        type="date"
        className='max-w-[180px]'
        register={register}
        error={errors.SLCend}
      />
      
      </div>
      </div>


      {!plustwo?(<div className='grid my-2 '>
      <Button className="" onClick={()=>addHandeler('Plustwo')}>10 + 2 details</Button>
      </div> ):(<div className='grid border-b border-slate'>
      <h3 className='text-lg md:text-xl text-center underline-offset-8 underline font-medium py-4'> 10 + 2 </h3>
      <div className="flex justify-start flex-wrap gap-3">
        <FormInput
        label="Subject"
        id="Isubject"
        type="text"
       
        register={register}
        error={errors.Isubject}
      />
      <FormInput
        label="Percentage / GPA"
        id="Ipercentage"
        type="text"
        register={register}
        error={errors.Ipercentage}
      />
      <FormInput
        label="Board"
        id="Iboard"
        type="text"
        register={register}
        error={errors.Iboard}
      />
      
      </div>
      <div className="flex justify-start flex-wrap gap-3">
      <FormInput
        label="Backlog"
        id="Ibacklog"
        type="number"
      
        className='max-w-[150px]'
        register={register}
        error={errors.Ibacklog}
      />
      <FormInput
        label="Start"
        id="Ijoined"
        type="date"
      
        className='max-w-[180px]'
        register={register}
        error={errors.Ijoined}
      />
      <FormInput
        label="End"
        id="Iend"
        type="date"
        className='max-w-[180px]'
        register={register}
        error={errors.Iend}
      />
      
      </div>
      <div className='grid my-2 '>
      <Button className="" onClick={()=>removeHandeler('Plustwo')}>Remove</Button>
      </div>
      </div>
      )   
}
      {!bachelor?(<div className='grid my-2 '>
      <Button className="" onClick={()=>addHandeler('Bachelor')}>Bachelor</Button>
      </div> ):(<div className='grid border-b border-slate'>
      <h3 className='text-lg md:text-xl text-center underline-offset-8 underline font-medium py-4'> Bachelor </h3>
      <div className="flex justify-start flex-wrap gap-3">
        <FormInput
        label="Subject"
        id="bsubject"
        type="text"
        
        register={register}
        error={errors.bsubject}
      />
      <FormInput
        label="Percentage / GPA"
        id="bpercentage"
        type="text"
        
        register={register}
        error={errors.bpercentage}
      />
      <FormInput
        label="Board"
        id="bboard"
        type="text"
       
       
        register={register}
        error={errors.bboard}
      />
      </div>
      <div className="flex justify-start flex-wrap gap-3">
      <FormInput
        label="Backlog"
        id="bbacklog"
        type="number"
       
        className='max-w-[150px]'
        register={register}
        error={errors.bbacklog}
      />
      
      <FormInput
        label="Start"
        id="bjoined"
        type="date"
       
        className='max-w-[180px]'
        register={register}
        error={errors.bjoined}
      />
      <FormInput
        label="End"
        id="bend"
        type="date"
        
        className='max-w-[180px]'
        register={register}
        error={errors.bend}
      />
      </div>
      
      <div className='grid my-2 '>
      <Button className="" onClick={()=>removeHandeler('Bachelor')}>Remove</Button>
      </div>
      </div>
      )   
}
      {!master?(<div className='grid my-2 '>
      <Button className="" onClick={()=>addHandeler('Master')}>Master</Button>
      </div> ):(<div className='grid border-b border-slate'>
      <h3 className='text-lg md:text-xl text-center underline-offset-8 underline font-medium py-4'>Master</h3>
        <div className="flex justify-start flex-wrap gap-3">
        <FormInput
        label="Subject"
        id="msubject"
        type="text"
    
        
        register={register}
        error={errors.msubject}
      />
      <FormInput
        label="Percentage / GPA"
        id="mpercentage"
        type="text"
      
        register={register}
        error={errors.mpercentage}
      />
       <FormInput
        label="Board"
        id="mboard"
        type="text"
       
        register={register}
        error={errors.mboard}
      />
      </div>
      <div className="flex justify-start flex-wrap gap-3">
      <FormInput
        label="Backlog"
        id="mbacklog"
        type="number"
        
        className='max-w-[150px]'
        register={register}
        error={errors.mbacklog}
      />
      <FormInput
        label="Start"
        id="mjoined"
        type="date"
        
        className='max-w-[180px]'
        register={register}
        error={errors.mjoined}
      />
      <FormInput
        label="End"
        id="mend"
        type="date"
       
        className='max-w-[180px]'
        register={register}
        error={errors.mend}
      />
      </div>
     
      <div className='grid my-2 '>
      <Button className="" onClick={()=>removeHandeler('Master')}>Remove</Button>
      </div>
      </div>
      )   
}

      
    </div>
  )
}

export default EducationInfo

// SLCsubject: Yup.string(),
// SLCpercentage: Yup.number(),
// SLCbacklog: Yup.number(),
// SLCend: Yup.date(),
// SLCboard: Yup.string(),
// Isubject: Yup.string(),
// Ipercentage: Yup.number(),
// Ibacklog: Yup.number(),
// Ijoined:Yup.date(),
// Iend: Yup.date(),
// Iboard: Yup.string(),
// bsubject: Yup.string(),
// bpercentage: Yup.number(),
// bbacklog: Yup.number(),
// bjoined:Yup.date(),
// bend: Yup.date(),
// bboard: Yup.string(),
// msubject: Yup.string(),
// mpercentage: Yup.number(),
// mbacklog: Yup.number(),
// mjoined:Yup.date(),
// mend: Yup.date(),
// mboard: Yup.string(),
