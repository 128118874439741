import React from "react";
import { Menu } from "@headlessui/react";
import {Link} from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const MenuClone = ({ label, linkURL }) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <Link
          to={linkURL}
          className={classNames(
            active ? "bg-primary text-white" : "",
            "block px-4 py-2 text-sm  text-black font-semibold"
          )}
        >
          {label}
        </Link>
      )}
    </Menu.Item>
  );
};

export default MenuClone;
