import React from "react";

const SubmissionMessage = () => {
  return (
    <div className="grid justify-center w-2/5 m-auto p-4 font-Poppins text-xl">
      <p className="p-4 text-center"> Form is successfully submitted</p>
      <p className="p-4 text-center"> Thank you for your valuable time</p>
    </div>
  );
};

export default SubmissionMessage;
