import React from 'react'
import FormInput from '../UI/FormInput'

const IeltsInfo = ({ register, errors }) => {
  return (
    <div className='flex flex-col'>
      <div className="flex justify-start flex-wrap gap-3">
      <FormInput
        label="Speaking"
        id="speaking"
        type="number"
        className='max-w-[8rem]'
        register={register}
        error={errors.speaking}
      />
      <FormInput
        label="Listening"
        id="listening"
        type="number"
        className='max-w-[8rem]'
        register={register}
        error={errors.listening}
      />
      <FormInput
        label="Reading"
        id="Reading"
        type="number"
        className='max-w-[8rem]'
        register={register}
        error={errors.Reading}
      />
      </div>
      <div className="flex justify-start flex-wrap gap-3">
      <FormInput
        label="Writing"
        id="writing"
        type="number"
        
        className='max-w-[8rem]'
        register={register}
        error={errors.writing}
      />
      <FormInput
        label="Gap"
        id="gap"
        type="number"
        placeholder="if any"
        className='max-w-[8rem]'
        register={register}
        error={errors.gap}
      />
      <FormInput
        label="Reason For Gap"
        id="rog"
        type="text"
        register={register}
        error={errors.rog}
      />
      </div>

    </div>
  )
}

export default IeltsInfo
