import React, { useState, useEffect } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useParams } from "react-router-dom";
import { createClient } from "contentful";
import BannerwithText from "../../common/BannerwithText";
import { styleRichText } from "../../common/CustomRichText";
import Loader from "../../common/Loader";

const ServiceDetail = () => {
  const { slug } = useParams();

  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);

  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  });

  useEffect(() => {
    const getBlog = async () => {
      try {
        setLoading(true);
        const entries = await client.getEntries({
          content_type: "services",
          "fields.slug": slug,
        });

        return entries.items;
      } catch (error) {
        console.log(`Error fetching blog ${error}`);
      } finally {
        setLoading(false);
      }
    };
    getBlog().then((res) => {
      setServices(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);
  console.log(services);

  if (loading) {
    return (
      <div className="h-[50vh] w-screen flex justify-center items-center">
        <Loader />
      </div>
    );
  } else {
    return (
      <div className=" bg-[#f9f9fa] font-Bitter   ">
        {services?.map((item, index) => {
          return (
            <div className="flex  flex-col whitespace-normal " key={index}>
              <BannerwithText
                ImageUrl={item.fields.bannerImage.fields.file.url}
                Text={item.fields.heading}
                Slug={slug}
              />

              <div className="max-w-7xl mx-auto py-8">
                {documentToReactComponents(item.fields.details, styleRichText)}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
};

export default ServiceDetail;
