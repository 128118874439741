import Carousel from "./Carousel";
import Header from "./Header";
import Blog from "../Blog/Blog";
import StudyCards from "./DestinationSection/StudyCards";
import Services from "./Services/Services";
import Testimonial from "./Testimonial";
import Seo from "../../SEO/Seo";
// import LogoSlider from "./LogoSlider";
import LogoCarousel from "./LogoCarousel";
// import ServiceDetail from "./Services/ServiceDetail";

const Homepage = () => {
  return (
    <div>
      <Seo
        title="Top Education Consultancy | Footstep Education Consultancy"
        description="We offer the best IELTS and PTE classes, exam preparation, and study abroad consultancy services. Our experienced consultants can help you achieve your dream of studying abroad."
        name="Footstep Education Consultancy"
        type="article"
      />
      <Carousel autoSlide={true} />
      {/* <LogoSlider /> */}
      <LogoCarousel/>
      <Header />
      <StudyCards />

      <Services />
      {/* <ServiceDetail /> */}
      <Blog />
      <Testimonial />
    </div>
  );
};

export default Homepage;
