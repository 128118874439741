import React from 'react'
import EnquiryForm from '../components/enquiry/enquiryForm'

const Enquiry = () => {

  return (
    <div>
        <div className='bg-primary text-xl font-Poppins font-bold text-white h-24 text-center grid items-center mb-4'>Enquiry Form</div>
       
      <EnquiryForm/>
    </div>
  )
}

export default Enquiry
