import React, { useState, useEffect } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import BlogCard from "./BlogCard";
import FetchContentful from "../common/FetchContentful";
import Button from "../common/Button";
import { Link } from "react-router-dom";

const Blog = () => {
  const [Blog, setBlog] = useState([]);
  const { getBlog } = FetchContentful();
  const showBlog = Blog.slice(0, 3);

  useEffect(() => {
    getBlog("blog").then((res) => {
      setBlog(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex  font-Nunito bg-[#f9f9fa] flex-col justify-around mx-2 py-6 items-center">
      <h1 className="text-3xl font-bold ">LATEST BLOGS</h1>
      <h3 className=" text-2xl text-center font-semibold my-5 ">
        Stay Ahead with the Best - Read Our Latest Blogs Today
      </h3>

      <div className="flex items-center justify-center flex-wrap gap-4">
        {showBlog &&
          showBlog.map((item, index) => {
            return (
              <div key={index}>
                <BlogCard
                  heading={item.fields.heading}
                  thumbnailUrl={item.fields.thumbnailImage.fields.file.url}
                  slug={item.fields.slug}
                />{" "}
              </div>
            );
          })}
      </div>
      <Link to="/blogs" className="my-8">
        <Button>
          <span className="flex">
            View All{" "}
            <ChevronRightIcon className="font-bold mt-0.5 ml-3 h-5 w-5 text-white" />
          </span>
        </Button>
      </Link>
    </div>
  );
};

export default Blog;
