import React from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";
const Messenger = () => {
  return (
    <div className="">
      <MessengerCustomerChat pageId="102330555963809" appId="605558828163658" />
    </div>
  );
};

export default Messenger;
