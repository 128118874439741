import React, { useState, useEffect } from "react";
import ServiceCard from "./ServiceCard";
import FetchContentful from "../../common/FetchContentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Link } from "react-router-dom";

const Services = () => {
  const [services, setServices] = useState([]);

  const { getBlog } = FetchContentful();
  useEffect(() => {
    getBlog("services").then((res) => {
      setServices(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className=" px-3 md:px-8 bg-gray font-Nunito py-10 ">
      <div className=" text-center tracking-wide font-bold text-2xl md:text-4xl  my-3">
        STUDENT SERVICES
      </div>
      <div className="text-lg md:text-xl text-secondaryBlack text-center font-semibold m-3 p-3 ">
        Grab the Opputunity to advantage of these Services
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto ">
        {services?.map((item) => {
          return (
            <Link key={item.sys.id} to={`/services/${item.fields.slug}`}>
              <ServiceCard
                IconName={item.fields.iconName}
                Title={item.fields.heading}
                Description={documentToReactComponents(item.fields.description)}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Services;
