import React from "react";
import FormInput from "../UI/FormInput";
const PersonalInfo = ({ register, errors }) => {
  return (
    <div className="flex flex-col">
      <div className="flex justify-start flex-wrap gap-3">    
      <FormInput
        label="First Name"
        id="fname"
        type="text"
        register={register}
        error={errors.fname}
      />
      <FormInput
        label="Middle Name"
        id="mname"
        type="text"
        register={register}
        error={errors.mname}
      />
      <FormInput
        label="Last Name"
        id="lname"
        type="text"
        register={register}
        error={errors.lname}
      />
      </div>
      <div className="flex justify-start flex-wrap gap-3">
      <FormInput
        label="Address"
        id="address"
        type="text"
        register={register}
        error={errors.address}
      />
      <FormInput
        label="Contact"
        id="contact"
        className='max-w-[150px]'
        register={register}
        error={errors.contact}
      />
      <FormInput
        label="Birthdate"
        id="dob"
        type="date"
        className="max-w-[150px]"
        register={register}
        error={errors.dob}
      />
      </div>
      <div>

      
      <div className="flex justify-start flex-wrap gap-3">
      
       {/* gender  */}
    <div className="flex flex-col gap-2 items-start w-full sm:w-80 max-w-xs">
      <label htmlFor='gender' className="text-base md:text-xl font-Poppins mt-4">
        Gender
      </label>
      <select
      id='gender'
      className={`border  px-2 py-1 rounded-md w-full ${errors.gender?'border-red':'border-border'} `}
      {...register('gender')}>
        <option value="">Select</option>
        <option value="male">male</option>
        <option value="female">Female</option>
      </select>
      {errors.gender && <p className='text-red px-2'>{errors.gender.message}</p>}
      </div>
      {/* maritialstatus  */}
    <div className="flex flex-col gap-2 items-start w-full sm:w-80 max-w-xs">
      <label htmlFor='maritialstatus' className='text-base md:text-xl font-Poppins mt-4'>
        Marritial Status
      </label>
  
      <select
      id='maritialstatus'
      className={`border  px-2 py-1 rounded-md w-full ${errors.maritialstatus?'border-red':'border-border'}`}
      {...register('maritialstatus')}>
        <option value="">Select</option>
        <option value="unmarried">unmarried</option>
        <option value="married">married</option>
      </select>
      {errors.maritialstatus && <p className='text-red px-2'>{errors.maritialstatus.message}</p>}
      </div>
      </div>
      </div>
      <div className="flex justify-start flex-wrap gap-3" >   
      <FormInput
        label="Country Preference"
        id="country"
        type="text"
        className='max-w-xs'
        register={register}
        error={errors.country}
      />
      <FormInput
        label="Course Preference"
        id="course"
        type="text"
        className='max-w-xs'
        register={register}
        error={errors.course}
      />

      
      </div>
    </div>
  );
};

export default PersonalInfo;
