import React, { useState, useEffect } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useParams } from "react-router-dom";
import { createClient } from "contentful";

import { styleRichText } from "../common/CustomRichText";
import Loader from "../common/Loader";

const BlogDetail = () => {
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);

  const [Blog, setBlog] = useState([]);

  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  });

  const getBlog = async () => {
    try {
      setLoading(true);
      const entries = await client.getEntries({
        content_type: "blog",
        "fields.slug": slug,
      });

      return entries.items;
    } catch (error) {
      console.log(`Error fetching blog ${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBlog().then((res) => {
      setBlog(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className="h-[50vh] w-screen flex justify-center items-center">
        <Loader />
        {/* <Spinner /> */}
      </div>
    );
  } else {
    return (
      <div className="pb-10 bg-[#f9f9fa] font-Bitter   ">
        {Blog?.map((item, index) => {
          return (
            <div className="flex flex-col whitespace-normal " key={index}>
              <div className="flex flex-col  bg-primary px-5  md:px-32 text-white py-4 md:py-10">
                <h1 className=" text-2xl md:text-5xl font-bold   ">
                  {item.fields.heading}{" "}
                </h1>
                <h2 className=" text-lg md:text-2xl font-semibold">
                  {" "}
                  Home/ Blog /{slug}{" "}
                </h2>
              </div>
              <div className=" my-auto mx-auto ">
                <img
                  src={item.fields.bannerImage.fields.file.url}
                  alt="bg"
                  className="h-[50vh] w-full py-5"
                />
              </div>
              <div className="max-w-7xl mx-auto px-5">
                {documentToReactComponents(item.fields.article, styleRichText)}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
};

export default BlogDetail;
