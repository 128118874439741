import React, { useEffect, useState, useRef } from "react";

const logos = [
  {
    id: "1",
    name: "birmingham",
    image:
      "/images/logocarousel/birmingham.jpg",
  },
  {
    id: "2",
    name: "BPP",
    image: "/images/logocarousel/bpppng.png",
  },
  {
    id: "3",
    name: "chester",
    image: "/images/logocarousel/chester.jpg",
  },
  {
    id: "4",
    name: "creative arts",
    image:
      "/images/logocarousel/creativeart.jpg",
  },
  {
    id: "5",
    name: "DeMontfort",
    image: "/images/logocarousel/DeMontFort.jpg",
  },
  {
    id: "6",
    name: "East london",
    image: "/images/logocarousel/eastlondon.jpg",
  },
  {
    id: "7",
    name: "ravensbourne",
    image:
      "/images/logocarousel/ravensbourne.png",
  },
  {
    id: "8",
    name: "roehampton",
    image: "/images/logocarousel/roehampton.jpg",
  },
  
];


    const LogoCarousel = () => {
        const [position, setPosition] = useState(0);
        const containerRef = useRef(null);
        const logosRef = useRef(null);
        const [containerWidth, setContainerWidth] = useState(0);
        const [logosWidth, setLogosWidth] = useState(0);
      
        useEffect(() => {
          const containerWidth = containerRef.current.offsetWidth;
          const logosWidth = logosRef.current.offsetWidth;
          setContainerWidth(containerWidth);
          setLogosWidth(logosWidth);
          const interval = setInterval(() => {
            setPosition((position) => {
              const newPosition = position - 1;
              if (newPosition < -logosWidth) {
                return newPosition + logosWidth;
              }
              return newPosition;
            });
          }, 10);
          return () => clearInterval(interval);
        }, [logosWidth]);
  return (
    <div className="relative h-24 w-full overflow-hidden "
    ref={containerRef}>
      <div
        className="absolute left-0 h-full flex"
        style={{ transform: `translateX(${position}px)` }}
        ref={logosRef}
      >
        {logos.map((logo) => {
          return (
            <img
              src={logo.image}
              alt={logo.name}
              key={logo.id}
              className="h-24 mx-4 p-6"
            />
          );
        })}
        {/* these two extra rendering is done for the illusion of smooth scrolling */}
        {logos.map((logo) => {
          return (
            <img
              src={logo.image}
              alt={logo.name}
              key={logo.id}
              className="h-24 mx-4 p-6"
            />
          );
        })}
        {logos.map((logo) => {
          return (
            <img
              src={logo.image}
              alt={logo.name}
              key={logo.id}
              className="h-24 mx-4 p-6"
            />
          );
        })}
      </div>
      
    </div>
  );
};

export default LogoCarousel;
