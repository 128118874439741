import React, { useState } from "react";
import { Icon } from "@iconify/react";

const ModalView = ({
  clicked,
  handelNext,
  setClickedContent,
  handelPrev,
  contentType,
}) => {
  const handelClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setClickedContent(null);
    }
  };


  return (
    <div
      className="fixed z-10 top-0 left-0 right-0 inset-0 bg-overlay  w-full h-full flex items-center dismiss"
      onClick={handelClick}
    >
      <div className="block max-w-[60%] max-h-[80%] my-16 mx-auto dismiss">
        { contentType === 'image'?(
          <img src={clicked} alt="bigImage" />
        ):(
         <video controls autoPlay='true'  className="h-96"> 
            <source src={clicked} type='video/mp4'/>
            <source src={clicked} type="video/webm" />
            <source src={clicked} type="video/ogg" />
            your browser donot support video tag
        </video>
        )

        }
      </div>

      <span
        className="absolute right-5 top-5 text-2xl text-white z-50 cursor-pointer dismiss "
        onClick={handelClick}
      >
        X
      </span>
      <div class="flex bg-black justify-between absolute top-1/2 left-5 w-12 h-12 z-50  rounded-full text-white cursor-pointer">
        <Icon
          icon="material-symbols:arrow-circle-left"
          className="h-12 w-12"
          onClick={handelPrev}
        />
      </div>
      <div
        class="flex bg-black justify-between absolute top-1/2 right-5 w-12 h-12 z-50   rounded-full text-white cursor-pointer"
        onClick={handelNext}
      >
        <Icon
          icon="material-symbols:arrow-circle-right"
          className="h-12 w-12"
        />
      </div>
    </div>
  );
};

export default ModalView;
