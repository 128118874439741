import React, { useState, useEffect } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useParams } from "react-router-dom";
import { createClient } from "contentful";

import { styleRichText } from "../../common/CustomRichText";
import BannerwithText from "../../common/BannerwithText";
import Loader from "../../common/Loader";

const StudyDetail = () => {
  const { slug } = useParams();

  const [destination, setDestination] = useState([]);
  const [loading, setLoading] = useState(false);
  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  });

  useEffect(() => {
    const getBlog = async () => {
      try {
        setLoading(true);
        const entries = await client.getEntries({
          content_type: "studyDestination",
          "fields.slug": slug,
        });

        return entries.items;
      } catch (error) {
        console.log(`Error fetching blog ${error}`);
      } finally {
        setLoading(false);
      }
    };
    getBlog().then((res) => {
      setDestination(res);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);
  console.log(destination);

  if (loading) {
    return (
      <div className="h-[50vh] w-screen flex justify-center items-center">
        <Loader />
      </div>
    );
  } else {
    return (
      <div className=" pb-5 md:pb-10 bg-[#f9f9fa] font-Bitter   ">
        {destination?.map((item, index) => {
          return (
            <div
              className={`flex flex-col tracking-wider whitespace-normal`}
              key={index}
            >
              <BannerwithText
                ImageUrl={item.fields.bannerImage.fields.file.url}
                Text={item.fields.countryName}
                Slug={slug}
              />
              {/* <div className=" max-w-7xl mx-auto "></div> */}
              <div className="max-w-7xl py-8 mx-auto">
                {documentToReactComponents(item.fields.article, styleRichText)}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
};

export default StudyDetail;
