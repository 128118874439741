import React from 'react'
import FormInput from '../UI/FormInput';

const AdditionalInfo = ({register,errors}) => {
  return (
    <div className='flex flex-col'>
      <div className="flex justify-start flex-wrap gap-3">
      <FormInput
        label="Date Of Marriage"
        id="marriagedate"
        type="date"
        
        className='max-w-[150px]'
        register={register}
        error={errors.marriagedate}
      />
      {/* spouse qualification  */}
      <div className="flex flex-col gap-2 items-start w-full sm:w-80 max-w-xs">
      <label htmlFor='gender' className="text-base md:text-xl font-Poppins mt-4">
        Spouse Qualification
      </label>

      <select
      id='spouseQualification'
      className={`border  px-2 py-1 rounded-md w-full ${errors.spouseQualification?'border-red':'border-border'}`}
      {...register('spouseQualification')}>
        <option value="">Select</option>
        <option value="SLC/SEE">SLC/SEE</option>
        <option value="10 + 2">10 + 2</option>
        <option value="Bachelor">Bachelor</option>
        <option value="Master">Master</option>
        <option value="PHD">PHD</option>
      </select>
      {errors.spouseQualification && <p className='text-red px-2'>{errors.spouseQualification.message}</p>}
 
      </div>

      <FormInput
        label="Spouse Employment"
        id="spouseEmployment"
        type="text"
        register={register}
        error={errors.spouseEmployment}
      />
      </div>
      <div className="flex justify-start flex-wrap gap-3">
      <FormInput
        label="Spouse Income"
        id="spouseIncome"
        type="number"
       
        register={register}
        error={errors.spouseIncome}
      />
      <FormInput
        label="Number of Kids"
        id="kidsnumber"
        type="number"
      
        register={register}
        error={errors.kidsnumber}
      />
      </div>
      <div className="flex justify-start flex-wrap gap-3">
      <FormInput
        label="Accompanying Dependent No."
        id="dependentno"
        type="number"
     
        register={register}
        error={errors.dependentno}
      />
      <FormInput
        label="Visa History"
        id="visahistory"
        type="number"
        
        register={register}
        error={errors.visahistory}
      />
        </div>

 {/* comments   */}
 <div className="flex flex-col gap-2 items-start w-full">
      <label htmlFor='gender' className="text-base md:text-xl font-Poppins mt-4">
        comments
      </label>
    
      <textarea 
      rows='3'
      {...register('comment') }className="border px-2 py-1 rounded-md w-full  "placeholder='If you any comment you can add' />
      {errors.comment && <p className='text-red  px-2'>{errors.comment.message}</p>}
      
      </div>

 {/* applied before  */}
 <div className="flex flex-col gap-2 items-start w-full ">
      <label htmlFor='gender' className="text-base md:text-xl font-Poppins mt-4">
        have you or your spouse applied for any visa prior to this?
      </label>
  
      <select
      id='appliedbefore'
      className={`border  px-2 py-1 rounded-md w-full sm:w-80 max-w-xs ${errors.appliedbefore?'border-red':'border-border'}`}
      {...register('appliedbefore')}>
        <option value="">Select</option>
        <option value="applied before">Yes, I had</option>
        <option value="not applied before">No, I hadn't</option>

      </select>
      {errors.appliedbefore && <p className='text-red px-2'>{errors.appliedbefore.message}</p>}
      
      </div>

      {/* refusal  */}
      <div className="flex flex-col gap-2 items-start w-full ">
      <label htmlFor='gender' className="text-base md:text-xl font-Poppins mt-4">
        have you or your spouse had any previous visa refusal?
      </label>
 
      <select
      id='refusal'
      className={`border  px-2 py-1 rounded-md w-full sm:w-80 max-w-xs ${errors.refusal?'border-red':'border-border'}`}
      {...register('refusal')}>
        <option value="">Select</option>
        <option value="refusal before">Yes, I do</option>
        <option value="no any refusal">No, I don't</option>

      </select>
      {errors.refusal && <p className='text-red  px-2'>{errors.refusal.message}</p>}
      </div>
     


{/* Notes  */}
      <div className="flex flex-col gap-2 items-start w-full">
      <label htmlFor='gender' className="text-base md:text-xl font-Poppins mt-4">
        Notes
      </label>
      <textarea 
      rows='5'
      {...register('Notes') }className="border px-2 py-1 rounded-md w-full "placeholder='Add any additional information you feel required' />
      {errors.Notes && <p className='text-red  px-2'>{errors.Notes.message}</p>}
      
      </div>

      

    </div>
  )
}

export default AdditionalInfo;
