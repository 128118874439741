import { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Navlinks from "./NavlinksLayout";
import AppleStore from "./MobileLayot";
import Button from "../../common/Button";
import { Link } from "react-router-dom";
import FetchContentful from "../../common/FetchContentful";
import { useState, useEffect } from "react";

export default function Example() {
  const [navlinks, setNavLinks] = useState([]);
  const { getBlog } = FetchContentful();

  useEffect(() => {
    getBlog("navbar").then((res) => {
      setNavLinks(res[0].fields.navbar.NavbarLinks);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Disclosure
      as="nav"
      className="bg-white sticky font-Poppins top-0 z-10 text-black"
    >
      {({ open }) => (
        <>
          <div className=" px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              {/* child 1 */}

              <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              <div className="flex  flex-1 items-center justify-center lg:justify-between ">
                {/* Logo goes Here */}
                <div className="flex flex-shrink-0 items-center">
                  <Link to="/">
                    <img
                      className="block h-8 w-auto md:hidden"
                      src="/images/logo.png"
                      alt="Your Company"
                    />
                  </Link>
                  <Link to="/">
                    <img
                      className="hidden h-14 w-auto md:block"
                      src="/images/logo.png"
                      alt="Your Company"
                    />
                  </Link>
                </div>

                <div className="hidden  lg:block">
                  <div className="flex flex-wrap gap-2 items-center space-x-2 xl:space-x-4">
                    {navlinks?.map((item) => {
                      return (
                        <Navlinks
                          key={item.id}
                          title={item.title}
                          subTopics={item.subTopics}
                        />
                      );
                    })}

                    <Link to="/blogs">
                      <h1 className="text-sm mt-1 font-semibold text-black">
                        Blogs
                      </h1>
                    </Link>
                    <Link to="/gallery">
                      <h1 className="text-sm mt-1 font-semibold text-black">
                        Gallery
                      </h1>
                    </Link>
                    <Link to="/enquiry">
                      <h1 className="text-sm mt-1 font-semibold text-black">
                        Enquiry
                      </h1>
                    </Link>

                    <Link to="/contact">
                      <Button
                        type="button"
                        className="px-3 py-2 bg-primary font-semibold text-white  text-sm rounded-md hover:bg-secondary lg:hidden xl:block "
                      >
                        Contact Us
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* This  disclosure pannel is For Mobile Dropdoen */}

          <Disclosure.Panel className="lg:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navlinks.map((item) => {
                return (
                  <AppleStore
                    key={item.id}
                    title={item.title}
                    subTopics={item.subTopics}
                  />
                );
              })}
              <Link to="/blogs">
                <h1 className="text-xs mt-3 font-semibold px-6 text-black">
                  Blogs
                </h1>
              </Link>
              <Link to="/gallery">
                <h1 className="text-xs mt-3 font-semibold px-6 text-black">
                  Gallery
                </h1>
              </Link>
              <Link to="/enquiry">
                <h1 className="text-xs mt-3 font-semibold px-6 text-black">
                  enquiry
                </h1>
              </Link>

              <button
                type="button"
                className="px-3 mx-6 py-2 font-semibold bg-primary text-white  text-xs rounded-md lg:hidden xl:block "
              >
                <Link to="/contact">Contact Us </Link>
              </button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
