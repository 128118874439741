import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import MessengerCustomerChat from "react-messenger-customer-chat";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <MessengerCustomerChat pageId="100085629246794" appId="143189962009729" />,

  <React.StrictMode>
    <App />
  </React.StrictMode>
  // </BrowserRouter>
);
