import React, { useState, useEffect } from "react";
import BlogCard from "./BlogCard";

import Seo from '../../SEO/Seo';
import FetchContentful from "../common/FetchContentful";
import BannerwithText from "../common/BannerwithText";

const Blogs = () => {
  const [Blog, setBlog] = useState([]);
  const { getBlog } = FetchContentful();

  useEffect(() => {
    getBlog("blog").then((res) => {
      setBlog(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
   <Seo
        title="Blog| Footstep Education Consultancy"
        description="We offer the best IELTS and PTE classes, exam preparation, and study abroad consultancy services. Our experienced consultants can help you achieve your dream of studying abroad."
        name="Footstep Education Consultancy"
        type="article"
      />
    <div className="flex font-Bitter flex-col ">
      <div className="flex flex-col w-screen text-white">
        <BannerwithText
          ImageUrl="/images/destination/canada.jpg"
          Text={"BLOG"}
          Slug={"Blogs"}
        />
        {/* <h1 className="text-5xl font-bold   ">BLOG </h1>
        <h2 className="text-2xl font-semibold"> Home/ Blogs </h2> */}
      </div>
      <div className="bg-[#f9f9fa]">
        <div className="max-w-7xl mx-auto ">
          <div className="flex items-center justify-center flex-wrap gap-4 md:py-10 md:gap-10">
            {Blog.map((item, index) => {
              return (
                <div key={index}>
                  <BlogCard
                    heading={item.fields.heading}
                    thumbnailUrl={item.fields.thumbnailImage.fields.file.url}
                    slug={item.fields.slug}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    
    </div>
    </>

  );
};

export default Blogs;
