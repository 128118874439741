import { useForm } from "react-hook-form";
// import FormInput from "../UI/FormInput";
import Button from "../common/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  cname: Yup.string().required("Name is required"),
  cemail: Yup.string().email("invalid email format").required("Email is required"),
  ccontact: Yup.number("invalid")
  .typeError("Contact Number is Required")
  .required("Required").test('is-ten-digits', 'Contact must be 10 digits', (value) => {
    if (value === undefined || value === null) {
      return true; 
    }
    const stringValue = value.toString();
    return stringValue.length === 10 && /^\d+$/.test(stringValue); 
  }),
  ccity: Yup.string().required("your address is required"),
  cmessage: Yup.string().required("Please Type your message"),
});


const Contactform = () => {
  const {
    handleSubmit,
    register,
    formState: {errors,isSubmitting, isValid},
    reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    fetch('https://formspree.io/f/xvonvzko', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }); 
    reset();
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5">
          <div className="w-full flex flex-col">
          <input
            type="text"
            id="cname"
            placeholder="John Doe"
            className={`border px-2 py-1 rounded-md w-full ${errors.cname?'border-red':'border-border'}`}
            {...register("cname")}
          />
         {errors.cname && <p className='text-red px-2'>{errors.cname.message}</p>}
          </div>
          <div className="w-full flex flex-col">
          <input
            type="email"
            id="cemail"
            placeholder="example@example.com"
            className={`border px-2 py-1 rounded-md w-full ${errors.cemail?'border-red':'border-border'}`}
            {...register("cemail")}
          />
         {errors.cemail && <p className='text-red px-2'>{errors.cemail.message}</p>}
          </div>
          <div className="w-full flex flex-col">
          <input
            type="number"
            id="ccontact"
            placeholder="Your Contact Number"
            className={`border px-2 py-1 rounded-md w-full ${errors.ccontact?'border-red':'border-border'}`}
            {...register("ccontact",{minLength:10, maxLength:10})}
          />
         {errors.ccontact && <p className='text-red px-2'>{errors.ccontact.message}</p>}
          </div>
          <div className="w-full flex flex-col">
          <input
            type="text"
            id="ccity"
            placeholder="Your City"
            className={`border px-2 py-1 rounded-md w-full ${errors.ccity?'border-red':'border-border'}`}
            {...register("ccity")}
          />
         {errors.ccity && <p className='text-red px-2'>{errors.ccity.message}</p>}
          </div>
          <div className="w-full flex flex-col">
          <textarea
          rows='2'
            id="cmessage"
            placeholder="Write your message"
            className={`border px-2 py-1 rounded-md w-full ${errors.cmessage?'border-red':'border-border'}`}
            {...register("cmessage")}
          />
         {errors.cmessage && <p className='text-red px-2'>{errors.cmessage.message}</p>}
          </div>

          <div className="grid mt-3">
            <Button type="submit"
            disabled={isSubmitting || !isValid}
            >Submit</Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Contactform;
