import React from "react";

const CountryCard = ({ ImageUrl, Title }) => {
  return (
    <div className="grid relative hover:scale-105 transition delay-150 ease-in-out font-Nunito ">
      <div className=" m-4 lg:max-w-[26rem] md:max-w-[18]">
        <img
          src={ImageUrl}
          alt="banner"
          className="border border-white rounded-lg lg:max-w-[26rem] brightness-50"
        />
      </div>
      <div className="absolute px-16 inset-y-0 flex justify-center items-center align-middle  tracking-wider   text-gray hover:text-white font-semibold  lg:text-4xl md:text-3xl text-2xl hover:scale-105 transition delay-150 ease-in-out">
        Study in {Title}
      </div>
    </div>
  );
};

export default CountryCard;
