import { useEffect, useState } from "react";
import ModalView from "../UI/ModalView";
import FetchContentful from "../common/FetchContentful";
import Loader from "../common/Loader";

const PhotoList = () => {
  const [imageArray, setImageArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clickedContent, setClickedContent] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const { getImage } = FetchContentful();

  useEffect(() => {
    setLoading(true);
    getImage("gallaries").then((res) => {
      console.log("respone:",res)
      const newimages = res.map((response, index) =>
        response.fields.images.map((newimage, index) => ({
          name: newimage.fields.title,
          url: newimage.fields.file.url,
        }))
      );
      setImageArray(newimages);
      setLoading(false);
    });
  }, []);

  const handelClicked = (items, index) => {
    setCurrentIndex(index);
    setClickedContent(items.url);
  };

  const handelNext = () => {
    const totallength = image.length;
    if (currentIndex + 1 >= totallength) {
      setCurrentIndex(0);
      const newUrl = image[0].url;
      setClickedContent(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = image.filter((item) => {
      return image.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].url;
    setClickedContent(newItem);
    setCurrentIndex(newIndex);
  };
  const handelPrev = () => {
    const totallength = image.length;
    if (currentIndex === 0) {
      setCurrentIndex(totallength - 1);
      const newUrl = image[totallength - 1].url;
      setClickedContent(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = image.filter((item) => {
      return image.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].url;
    setClickedContent(newItem);
    setCurrentIndex(newIndex);
  };

  const image = imageArray.reduce((merged, arr) => merged.concat(arr), []);

  if (loading) {
    return (
      <div className="h-[50vh] w-screen flex justify-center items-center">
        <Loader />
      </div>
    );
  } else {
    return (
      <div className="flex flex-wrap justify-around items-center">
        {image.map((items, index) => {
          return (
            <div
              key={items.index}
              className="flex justify-center m-4 "
              onClick={() => handelClicked(items, index)}
            >
              <img
                src={items.url}
                alt={items.name}
                onClick={() => handelClicked(items, index)}
                className="h-44"
              />
            </div>
          );
        })}
        <div>
          {clickedContent && (
            <ModalView
              clicked={clickedContent}
              handelNext={handelNext}
              setClickedContent={setClickedContent}
              handelPrev={handelPrev}
              contentType="image"
            />
          )}
        </div>
      </div>
    );
  }
};

export default PhotoList;
