import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";

const AppleStore = (props) => {
  const { title, subTopics } = props;
  return (
    <>
      <Disclosure className=" px-6 block lg:hidden">
        {({ open }) => (
          <div className="">
            <Disclosure.Button className=" flex justify-between  w-full  text-right float-right   py-2 text-sm font-medium  text-black focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
              <h1 className="  pb-2 text-left text-xs font-semibold text-gray-600">
                {title}
              </h1>
              <span>
                {open ? (
                  <ChevronDownIcon
                    className={`${open ? "" : ""} w-5 h-5 text-black`}
                  />
                ) : (
                  <ChevronRightIcon
                    className={`${open ? "" : ""} w-5 h-5 text-black`}
                  />
                )}
              </span>
            </Disclosure.Button>
            <Disclosure.Panel className="pb-2 text-sm ">
              <ul className="text-left text-xs ">
                {subTopics.map((item, index) => {
                  return (
                    <Link key={index} to={item.linkURL}>
                      <li className="py-1.5  ">{item.topic}</li>
                    </Link>
                  );
                })}
              </ul>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </>
  );
};

export default AppleStore;
