import React from "react";

import { Link } from "react-router-dom";

const Footer = () => {
  const AboutUs = [
    {
      id: 51,
      title: "About us",
      link: "/about-us",
    },
    {
      id: 52,
      title: "Contact Us",
      link: "/contact",
    },
    // {
    //   id: 53,
    //   title: "Visa Process",
    //   link: "/",
    // },
    // {
    //   id: 54,
    //   title: "Available Universities",
    //   link: "/",
    // },
    {
      id: 55,
      title: "Privacy Policy",
      link: "/privacy-policy",
    },
    {
      id: 56,
      title: "FAQs",
      link: "/",
    },
  ];

  const StudyAbroad = [
    {
      id: 61,
      title: "Study In Australia",
      link: "/abroad/study-in-australia",
    },
    {
      id: 62,
      title: "Study in New Zealand",
      link: "/abroad/study-in-newzealand",
    },
    {
      id: 63,
      title: "Study in USA",
      link: "/abroad/study-in-usa",
    },
    {
      id: 64,
      title: "Study in UK",
      link: "/abroad/study-in-uk",
    },
    {
      id: 65,
      title: "Study in Canada",
      link: "/abroad/study-in-canada",
    },
  ];
  const Services = [
    {
      id: 81,
      title: "Counselling",
      link: "/contact",
    },
    {
      id: 82,
      title: "Documentation",
      link: "/services/documentation-guidence",
    },
    // {
    //   id: 83,
    //   title: "Financing",
    //   link: "/documentation",
    // },
    {
      id: 84,
      title: "Scholarship Assistance",
      link: "/services/scholarship-assistance",
    },
    {
      id: 85,
      title: "Student Visa Declaration",
      link: "/services/student-visa-declaration-form",
    },
  ];

  return (
    <div className="bg-slate  text-white">
      <div className="grid grid-row-2 ">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-4 m-4 md:border-b md:border-gray lg:justify-center md:justify-stretch">
          <div className="grid grid-rows-2">
            <div className="px-1 py-5 flex flex-col font-Nunito text-gray justify-start">
              <img
                src="/images/logo.png"
                className="h-20 w-48"
                alt="footstep consultancy"
              />
            </div>
            <div className="text-sm font-thin tracking-wider px-1 py-5 text-justify">
              We provide professional counseling for abroad studies. We help
              students to choose a better study path to boost their career and
              future.
            </div>
          </div>
          <div className="grid lg:ml-4">
            <div className="flex items-center text-md font-normal tracking-wider">
              About
            </div>
            <div>
              {AboutUs.map((items) => {
                return (
                  <Link
                    key={items.id}
                    to={items.link}
                    className="flex font-thin items-center gap-4 pb-2 text-xs   hover:text-secondary cursor-pointer"
                  >
                    {items.title}
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="grid">
            <div className=" flex items-center text-md font-normal tracking-wider">
              Study Abroad
            </div>
            <div>
              {StudyAbroad.map((items) => {
                return (
                  <Link
                    key={items.id}
                    to={items.link}
                    className="flex font-thin items-center gap-4 pb-2 text-xs  hover:text-secondary cursor-pointer"
                  >
                    {items.title}
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="grid">
            <div className="flex items-center text-md font-normal tracking-wider">
              Services
            </div>
            <div>
              {Services.map((items) => {
                return (
                  <Link
                    key={items.id}
                    to={items.link}
                    className="flex font-thin items-center gap-4 pb-2 text-xs  hover:text-secondary cursor-pointer"
                  >
                    {items.title}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
        <div className="text-center text-sm my-2 hover:text-white cursor-pointer">
          &copy; 2023 FootSteps Education Consultancy || Powered by <a href="http://www.neptechpal.com.np" target="_blank" rel="noopener noreferrer">Nep Tech Pal</a> 
        </div>
      </div>
    </div>
  );
};

export default Footer;
