import React, { useState, useEffect } from "react";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
const Images = [
  "images/carousel/ielts.jpg",
  "images/carousel/studyabroad.jpg",
  "images/carousel/travel.jpg",
  // "/images/carousel/australia.png",
  // "/images/carousel/newzealand.png",
  // "/images/carousel/uk.png",
  // "/images/carousel/usa.png",
];

const Carousel = ({ autoSlide = false, autoSlideInterval = 3000 }) => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (!autoSlide) return;
    const slideInteval = setInterval(Next, autoSlideInterval);
    return () => {
      clearInterval(slideInteval);
    };
  }, [autoSlide, autoSlideInterval]);
  const prev = () => {
    setCurrent((curr) => (curr === 0 ? Images.length - 1 : curr - 1));
  };
  const Next = () => {
    setCurrent((curr) => (curr === Images.length - 1 ? 0 : curr + 1));
  };

  return (
    <div className="  relative sm:h-[70vh] overflow-clip  lg:h-[85vh]  bg-primary ">
      <div className=" flex overflow-hidden h-full w-full ">
        <div
          className=" flex transition-transform ease-out duration-500 bg-no-repeat "
          style={{ transform: `translateX(-${current * 100}%)` }}
        >
          {Images?.map((img, index) => {
            return (
              <div key={index} className="bg-white min-w-full">
                <img
                  src={img}
                  alt="dark"
                  className=" h-full w-full mx-auto   "
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex px-5 items-center absolute inset-0  w-full justify-between lg:px-20">
        <div className=" p-1 lg:p-3 cursor-pointer rounded-full bg-secondary  text-white hover:text-secondary hover:bg-white delay-200 ease-in">
          <ChevronLeftIcon onClick={prev} className="h-6 w-6  rounded-full " />
        </div>
        <div className=" p-1 lg:p-3 cursor-pointer rounded-full bg-secondary  text-white  hover:text-secondary hover:bg-white delay-200 ease-in">
          <ChevronRightIcon onClick={Next} className="h-6 w-6 " />
        </div>
      </div>

      <div className="absolute bottom-4 right-0 left-0  ">
        <div className="flex items-center justify-center ">
          {Images.map((_, i) => {
            return (
              <div
                key={i}
                className={`bg-white transition-all h-1.5 w-1.5 lg:h-3 lg:w-3 mx-2 rounded-full ${
                  current === i ? "p-1 lg:p-2" : "bg-opacity-50"
                }`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
