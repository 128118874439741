import React from "react";
import Navbar from "./navbar/Navbar";
import Contacts from "../Home/Contacts";
import Footer from "./footer/Footer";
import Gotop from "../UI/Gotop";
import Messenger from "../UI/Messenger";

const Layout = ({ children }) => {
  return (
    <div>
      <Contacts />
      <Navbar />
      <main>{children}</main>
      <div className="fixed bottom-16 right-5 grid gap-5">
      <Messenger />
      <Gotop />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
