import React from "react";
import Button from "../common/Button";
import { Link } from "react-router-dom";

const BlogCard = ({ heading, thumbnailUrl, slug }) => {
  return (
    <div className="group  relative flex flex-col h-auto w-96 bg-white p-4 drop-shadow-lg  rounded-md">
      <img
        src={thumbnailUrl}
        alt="thumbnail"
        className="h-fit w-fit rounded-lg"
      />

      <div className=" hidden  absolute top-0 left-0 bg-white/50  h-full w-full cursor-pointer group-hover:flex items-center justify-center ">
        <Link to={`/blogs/${slug}`}>
          <Button> Read More</Button>
        </Link>
      </div>
      <div className="my-5">
        <h1 className="text-xl font-bold">{heading}</h1>
      </div>
    </div>
  );
};

export default BlogCard;
