import React, { useState, useEffect } from "react";
import FetchContentful from "../../common/FetchContentful";
import CountryCard from "./CountryCard";
import { Link } from "react-router-dom";

const StudyCards = () => {
  const [destination, setDestination] = useState([]);

  const { getBlog } = FetchContentful();
  useEffect(() => {
    getBlog("studyDestination").then((res) => {
      setDestination(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="bg-white font-Nunito">
        <div className="text-center my-8 mx-auto p-4 max-w-4xl ">
          <div className="text-black font-bold text-2xl md:text-4xl my-3">
            TOP STUDY DESTINATIONS AROUND THE WORLD
          </div>
          <div className="text-lg text-secondaryBlack md:text-3xl font-medium m-5 p-3">
            Discover Your Pefect Destination For You And Make Your Dream Come
            True
          </div>
        </div>
      </div>
      <div className="grid xl:grid-cols-3 md:grid-cols-2 gap-4 m-4 justify-items-center max-w-7xl mx-auto">
        {destination?.map((item) => {
          return (
            <Link key={item.sys.id} to={`/abroad/${item.fields.slug}`}>
              <CountryCard
                ImageUrl={item.fields.backgroundImage.fields.file.url}
                Title={item.fields.countryName}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default StudyCards;
