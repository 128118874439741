import { createClient } from "contentful";

const FetchContentful = () => {
  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  });

  const getBlog = async (contentType) => {
    try {
      const entries = await client.getEntries({
        content_type: contentType,
        select: "fields",
      });
      return entries.items;
    } catch (error) {
      console.log(`Error fetching blog ${error}`);
    }
  };


  const getImage = async (contentType) => {
    try {
      const entries = await client.getEntries({
        content_type: contentType,
        select: "fields.images",
      });
      return entries.items;
    } catch (error) {
      console.log(`Error fetching media ${error}`);
    }
  };
  const getVideo = async (contentType) => {
    try {
      const entries = await client.getEntries({
        content_type: contentType,
        select: "fields.videos",
      });
      return entries.items;
    } catch (error) {
      console.log(`Error fetching media ${error}`);
    }
  };
  // const getContent = async (contentType) => {
  //   try {
  //     const entries = await client.getEntries({
  //       content_type: contentType,
  //       select: "fields.images",
  //     });
  //     return entries.items;
  //   } catch (error) {
  //     console.log(`Error fetching media ${error}`);
  //   }
  // };
  

  return { getBlog, getImage, getVideo};
};

export default FetchContentful;
