import PhotoList from '../components/Gallery/PhotoList';
import VideoList from '../components/Gallery/VideoList';
import React, { useState } from 'react';


const tabs = [
  { label: 'Photos', content: <PhotoList /> },
  { label: 'Videos', content: <VideoList /> },
];


const Tab = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="tab">
      <div className="flex justify-center">
        {props.tabs.map((tab, index) => (
          <div
            key={index}
            className={`text-lg bg-gray cursor-pointer drop-shadow-lg text-black rounded-full px-4 py-2 mx-4 ${index === activeTab ? 'bg-primary text-white' : ''}`}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="flex flex-wrap justify-around items-center">
        {props.tabs[activeTab].content}
      </div>
    </div>
  );
}



const Gallery = () => {
  return (
    <div className='my-4 mx-20'>
      <Tab tabs={tabs} />
    </div>
  )
}

export default Gallery;


 