import React from "react";
import { Icon } from "@iconify/react";
import Seo from "../SEO/Seo";
import {
  GlobeAsiaAustraliaIcon,
  PhoneIcon,
  MapPin,
} from "@heroicons/react/24/outline";
import Contactform from "../components/contact/contactform";


const Contact = () => {
  return (
    <div>
      <Seo
        title="Contact | Footstep Education Consultancy"
        description="We offer the best IELTS and PTE classes, exam preparation, and study abroad consultancy services. Contact us or mail us for more information"
        name="Footstep Education Consultancy"
        type="article"
      />
      <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-y-8 justify-items-center items-center">
        <div className="mx-12 p-12 w-[90%] ml-10 bg-primary text-white border border-border rounded-2xl">
          <h2 className="text-sm font-bold mb-4 md:text-2xl lg:text-4xl">
            FootStep Education Consultancy
          </h2>
          <p className="mb-4">
            We are a renowned Education and Visa Consulting Agency. We offer
            dependable, credible, and authentic advice to Nepalese students who
            want to study in the United Kingdom, the United States, Canada, or
            Australia. <br />
            We have an expert team with years of global experience to assist you
            in reaching your academic objectives.
            <br />
            <br />
            <span className="text-base flex justify-start">
              <Icon
                icon="material-symbols:location-on-outline"
                className="h-6 w-6"
              />
              Niva Galli, Pokhara 33700, Nepal
            </span>
            <address className="justify-start flex gap-1 flex-wrap md:gap-3 md:text-lg ">
              <h1 className=" flex gap-2 ">
                <span>
                  <GlobeAsiaAustraliaIcon className="h-6 w-6" />
                </span>
                <span>
                  <a href="mailto:edu.footstep@gmail.com">
                    edu.footstep@gmail.com
                  </a>
                </span>
              </h1>
              <br />
            </address>
            <phone className="justify-start flex gap-1 flex-wrap md:gap-3 md:text-lg">
              <h1 className="flex">
                <span>
                  <PhoneIcon className=" h-6 w-6" />
                </span>
                <a href="tel:061-590154">
                  <span className="mx-2">061-590154</span>
                </a>
                <a href="tel:+9779802855828">
                  <span className="mx-2">9802855828</span>
                </a>
              </h1>
            </phone>
          </p>
        </div>
        <div className="w-[90%] m-auto ">
          <h2 className="text-center text-sm font-bold mb-4 md:text-2xl lg:text-4xl uppercase text-primary">
            Reach Us
          </h2>
          <Contactform/>
        </div>
      </div>
      <div className="p-4 m-4">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3515.492933337105!2d83.98606047546451!3d28.222714475890978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6c773e51d0f75053%3A0x802d37ef876d43dc!2sFootstep%20Education%20Consultancy!5e0!3m2!1sen!2snp!4v1680847897791!5m2!1sen!2snp"
          width="600"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="office-location"
          className="w-[95%] m-auto"
        ></iframe>
      </div>
    </div>
  );
};
export default Contact;
