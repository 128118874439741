import React from "react";

const Button = (props) => {
  return (
    <button
      type={props.type}
      className="bg-primary text-white font-semibold hover:bg-secondary hover:scale-105 active:scale-95 duration-300 ease-in-out px-4 rounded-lg py-2"
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default Button;
