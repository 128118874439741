import { BLOCKS } from "@contentful/rich-text-types";
export const styleRichText = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p className=" text-xl ">{children} </p>;
    },
    [BLOCKS.UL_LIST]: (node) => <CustomList node={node} />,
    [BLOCKS.OL_LIST]: (node) => <CustomOrderList node={node} />,
    [BLOCKS.HEADING_1]: (node, children) => {
      return <h1 className=" text-6xl ">{children} </h1>;
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 className="text-5xl ">{children} </h2>;
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return <h3 className="text-4xl ">{children} </h3>;
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return <h4 className="text-3xl ">{children} </h4>;
    },
    [BLOCKS.HEADING_5]: (node, children) => {
      return <h5 className="text-2xl ">{children} </h5>;
    },
    [BLOCKS.HEADING_6]: (node, children) => {
      return <h6 className="text-xl ">{children} </h6>;
    },
  },
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > -1 && <br key={index} />, textSegment];
    }, []);
  },
};

function CustomList({ node }) {
  return (
    <ul className="text-xl list-disc ">
      {node.content?.map((item, i) => (
        <li className="ml-5 p-0.5" key={i}>
          {" "}
          {item.content[0].content[0].value}{" "}
        </li>
      ))}
    </ul>
  );
}

function CustomOrderList({ node }) {
  return (
    <ul className="text-xl list-decimal ">
      {node.content?.map((item, i) => (
        <li className="ml-5 p-0.5" key={i}>
          {" "}
          {item.content[0].content[0].value}{" "}
        </li>
      ))}
    </ul>
  );
}
