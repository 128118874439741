import React from 'react';

const Progress = ({ currentStep }) => {
  const steps = [1, 2, 3, 4,5];

  return (
    <div className="w-full grid grid-cols-5 ">
      {steps.map((step) => (
        <div
          key={step}
          className={`${
            currentStep >= step ? 'bg-primary' : 'bg-slate'
          } text-right mx-1 rounded-full relative h-2 md:h-4`}
        >
            {/* {
                step === 5 ? "":(<span className="absolute bg-white  rounded-full classd">
                .
              </span>)
            } */}
          
        </div>
      ))}
    </div>
  );
};

export default Progress;
